import { useCallback, useRef, useState } from 'react';
import { useFullscreen } from 'react-use';

import FullscreenExitIcon from 'mdi-react/FullscreenExitIcon';
import FullscreenIcon from 'mdi-react/FullscreenIcon';

import TopbarButton from '+components/TopbarButton';

const FullscreenButton = () => {
  const docRef = useRef(document.documentElement);
  const [show, setShow] = useState(false);
  const isFullscreen = useFullscreen(docRef, show);

  const onClick = useCallback(() => setShow((prev) => !prev), []);

  return (
    <TopbarButton
      type="button"
      title={isFullscreen ? 'Exit Fullscreen' : 'View Fullscreen'}
      onClick={onClick}
      data-tracking="fullscreen-button"
    >
      {isFullscreen ? (
        <FullscreenExitIcon size={28} />
      ) : (
        <FullscreenIcon size={28} />
      )}
    </TopbarButton>
  );
};

export default FullscreenButton;
