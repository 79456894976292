import PropTypes from '+prop-types';
import { Fragment, useCallback, useContext, useState } from 'react';

import styled from 'styled-components';

import CheckboxMultipleBlankIcon from 'mdi-react/CheckboxMultipleBlankIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import SdIcon from 'mdi-react/SdIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { Item } from '+components/Menu';

import Context from './Context';
import { PresetTag } from './QueryModeTag';

export const StyledItem = styled(Item)`
  padding: 2px 6px;
  font-size: 12px;
  margin-top: ${({ $isSave }) => $isSave && '5px'};

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  gap: ${({ $isSave }) => ($isSave ? 5 : 20)}px;

  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
    color: currentColor;
  }

  & > code {
    max-width: ${({ $isRecent }) => ($isRecent ? '100%' : '80%')};
    color: inherit;
    opacity: 0.6;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 0;
    ${({ $isRecent }) => $isRecent && 'text-align: left;'}
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  gap: 2px;
  padding: 0 2px 2px;

  opacity: 0;

  background: ${({ theme }) => theme.contextMenuBackground};

  transition: opacity 0.3s;

  ${StyledItem}:hover & {
    opacity: 1;
  }
`;

const Action = styled.button.attrs(() => ({ type: 'button' }))`
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  line-height: 1em;
  opacity: ${({ $active }) => ($active ? 0.8 : 0.6)};
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

const Input = styled.input`
  width: 0;
  flex: 1;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  line-height: 18px;
`;

const PresetItem = (props) => {
  const { item, isEditable, isNew, isSave, isRecent, ...rest } = props;
  const { onClone, onSave, onCancel, onRemove } = useContext(Context);

  const [title, setTitle] = useState(item.title || item.preset || '');
  const [wasFocused, setWasFocused] = useState(false);
  const [editTitle, setEditTitle] = useState(isNew);

  const onTitleChange = useCallback((event) => {
    setTitle(event?.target?.value);
  }, []);

  const onFocus = useCallback(
    (event) => {
      if (wasFocused) {
        return;
      }

      setWasFocused(true);

      event?.target?.select();
    },
    [wasFocused],
  );

  const onInputRef = useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  const onTitleEditClick = useCallback((event) => {
    event.stopPropagation();
    setEditTitle(true);
  }, []);

  const cancel = useCallback(() => {
    if (isNew) {
      onCancel();
    }

    setEditTitle(false);
  }, [onCancel, isNew, editTitle]);

  const save = useCallback(() => {
    if (isNew) {
      onSave({
        ...item,
        title,
      });
      return;
    }

    setEditTitle(false);
    if (item.title !== title) {
      onSave({
        ...item,
        title,
      });
    }
  }, [onSave, isNew, item, title]);

  const onSaveClick = useCallback(
    (event) => {
      event.stopPropagation();
      save();
    },
    [save],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        cancel();
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        save();
      }
    },
    [cancel, save],
  );

  const onDuplicateClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (item) {
        onClone(item);
      }
    },
    [item, onClone],
  );

  const onRemoveClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (item) {
        onRemove(item);
      }
    },
    [item, onRemove],
  );

  const overrideClick = editTitle ? { onClick: null } : {};

  return (
    <StyledItem
      {...rest}
      dismissOnExecute={!(isSave || isNew || editTitle)}
      $isSave={isSave || isNew || editTitle}
      $isRecent={isRecent}
      {...overrideClick}
    >
      {editTitle && (
        <Fragment>
          <Action title="Save" $active onClick={onSaveClick}>
            <SdIcon size={14} />
          </Action>
          <Input
            value={title}
            onChange={onTitleChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            ref={onInputRef}
            maxLength={50}
            autoFocus
          />
        </Fragment>
      )}
      {!editTitle && (
        <Fragment>
          {isSave && <PlusIcon size={14} />}
          {/* don't show tag lebel for thresholds because the label gets cut off. We only show threshold suggestions in one place so context isn't needed */}
          {item.mode && item.mode !== ContextTypes.thresholdFlow && (
            <PresetTag>{item.mode.toUpperCase()}</PresetTag>
          )}
          {!isRecent && <Title>{item.title || item.preset}</Title>}
          <code>{item.nql}</code>
          {!isSave && (
            <Actions>
              {isEditable && (
                <Action title="Edit title" onClick={onTitleEditClick}>
                  <PencilIcon size={12} />
                </Action>
              )}
              <Action title="Duplicate" onClick={onDuplicateClick}>
                <CheckboxMultipleBlankIcon size={12} />
              </Action>
              {isEditable && (
                <Action title="Remove" onClick={onRemoveClick}>
                  <DeleteIcon size={14} />
                </Action>
              )}
            </Actions>
          )}
        </Fragment>
      )}
    </StyledItem>
  );
};

PresetItem.propTypes = {
  item: PropTypes.shape().isRequired,
  isSave: PropTypes.bool,
  isEditable: PropTypes.bool,
  isNew: PropTypes.bool,
  isRecent: PropTypes.bool,
};

PresetItem.defaultProps = {
  isSave: false,
  isEditable: false,
  isNew: false,
  isRecent: false,
};

export default PresetItem;
