/* eslint-disable react/prop-types */
import { CellIpLabels, HeaderSubheaderCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories/someOfFieldsFilter';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  rowSelector: 'rowSelector',
  name: 'name',
  description: 'description',
  whitelist: 'whitelist',
  menu: 'menu',
};

const columnsCollection = ({ labelContext }) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.name]: BaseColumnFactory({
    accessor: Columns.name,
    Header: 'Name / Desc',
    Cell: HeaderSubheaderCell({
      propHeader: 'name',
      propSubheader: 'description',
    }),
    realAccessor: ['name', 'description'],
    filter: someOfFieldsFilter(['name', 'description']),
  }),
  [Columns.whitelist]: BaseColumnFactory({
    accessor: Columns.whitelist,
    Header: 'IP (CIDR) / Labels',
    Cell: ({ value: ips, row }) => {
      const original = getRowOriginal(row);
      return (
        <CellIpLabels
          ips={ips}
          labels={original?.allowlistnames}
          context={labelContext.ip}
          fetchLabels={labelContext.show}
        />
      );
    },
    realAccessor: ['allowlist', 'allowlistnames'],
    filter: someOfFieldsFilter(
      labelContext.show ? ['allowlist', 'allowlistnames'] : ['allowlist'],
    ),
  }),
  [Columns.menu]: MenuColumnFactory,
});

export const getColumns = getColumnsHelper(columnsCollection);
