import { createRoot } from 'react-dom/client';

import 'core-js/stable';

import Highcharts from 'highcharts';
import * as PIXI from 'pixi.js-legacy';

import 'regenerator-runtime/runtime';

import chameleon from '@chamaeleonidae/chmln';

import { config } from '@/config';
// import './customConsole';
import App from '@/pages/App';

// some highcharts libraries expect access to Highcharts on window
window.Highcharts = Highcharts;

if (config.environment === 'development') {
  chameleon.init(import.meta.env.VITE_APP_CHAMELEON_API_KEY, {
    fastUrl: import.meta.env.VITE_APP_CHAMELEON_URL,
  });
}

PIXI.utils.skipHello();

const root = createRoot(document.getElementById('app'));

root.render(<App />);
