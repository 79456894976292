/* eslint-disable no-unused-expressions */
import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import classNames from 'classnames';
import debounce from 'lodash.debounce';
import styled, { css } from 'styled-components';
import getCaretCoords from 'textarea-caret';

import FunctionVariantIcon from 'mdi-react/FunctionVariantIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { actions, FetchStates, selectors } from '@/redux/api/nql-complete';
import { selectors as gfSelectors } from '@/redux/globalFilters';

import Highlight from '+components/Highlight';
import * as Menu from '+components/Menu';
import ScrollBarOrigin from '+components/ScrollBar/smooth';
import { useListKeyboardNavigation, useMutableValue } from '+hooks';
import useEvent from '+hooks/useEvent';
import { selectors as contextTypesTheme } from '+theme/slices/contextTypes';
import { makeArgObserver, makeId } from '+utils/general';
import nqlLang from '+utils/nqlLang';

import AutocompleteItem from './components/AutocompleteItem';
import CodeBlock from './components/CodeBlock';
import Container from './components/Container';
import DocumentationPanel from './components/DocumentationPanel';
import Dropdown from './components/Dropdown';
import MenuLoader from './components/MenuLoader';
import TextArea from './components/TextArea';
import {
  applyDocTemplate,
  debouncedFetchSuggestions,
  defaultDocTemplateConfig,
  isVisible,
  startsWithLowerCase,
  stopFocusLoss,
  templates,
} from './utils';

const whiteSpaceRegex = /^\s+$/;

const NQLMenu = styled(Menu.Menu)``;

const ScrollBar = styled(ScrollBarOrigin).attrs({ showShadows: false })`
  max-height: 240px;
  min-width: 180px;
`;

const ItemMenuSuffix = styled.span`
  color: ${({ theme }) =>
    theme.name === 'dark' ? '#b9b9b9' : '#797979'} !important;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 5px;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  opacity: 0.5;
  vertical-align: middle;
  z-index: 1;

  ${({ $context }) =>
    $context &&
    css`
      background: transparent;
      color: ${contextTypesTheme[$context] ??
      contextTypesTheme[ContextTypes.flow]} !important;
      text-transform: uppercase;
      font-size: 8px;
      opacity: 1;
    `}
`;

const thresholdSet = new Set([
  ContextTypes.thresholdFlow,
  ContextTypes.thresholdDns,
]);

const countFnSet = new Set(['count', 'cnt']);
const isCountFunction = (name) => countFnSet.has(name);

export const NQLTextArea = Menu.withMenu((props) => {
  const {
    value,
    context,
    docTemplateConfig,
    invalid,
    disabled,
    readOnly,
    onSubmit,
    onEnterPress,
    onPresetItemClick,
    allowPresets,
    allowRecents,
    presetsLeftOffset,
    presetsWidthExtension,
    excludingEverythingField = true,
    ...tail
  } = props;
  const { doubleEscapeToRollback } = tail;

  const dispatch = useDispatch();

  const { showMenuXY, hideMenu } = Menu.useMenuActions();
  const { show } = Menu.useMenuState();

  const [text, setTextOrigin] = useState(value);
  const [menuItems, setMenuItems] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pageSize, setPageSize] = useState(9);
  const [listContainer, setListContainer] = useState(null);
  const scrollBarRef = useRef(null);
  const textAreaRef = useRef(null);
  const lastValue = useRef(null);
  const mutableState = useMutableValue({
    shouldSetCaret: false,
    caretPos: 0,
  });
  const containerRef = useRef();

  const [namespaceId] = useState(() => `nql_suggest_${makeId()}`);

  const setText = useCallback((newValue) => {
    setTextOrigin((prev) => {
      let next = newValue;
      if (typeof next === 'function') {
        next = next(prev);
      }
      return next;
    });
  }, []);

  const onChangeDebouncedFetchSuggestions = useMutableValue(
    makeArgObserver(debouncedFetchSuggestions(namespaceId)),
  );

  const docs = useSelector(selectors.getDocs);
  const docStatus = useSelector(selectors.getDocStatus);
  const thresholdTerms = useSelector(selectors.getThresholdTerms);
  const suggestionsData = useSelector(
    selectors.getSuggestionsData(namespaceId),
  );
  const suggestionsStatus = useSelector(selectors.getSuggestionsStatus);
  const searchedNQL = useSelector(gfSelectors.getQueryFilter());
  const preventMouseEnter = useRef(false);

  const thresholdFunctions = useMemo(
    () =>
      (thresholdTerms?.suggestions || [])
        .filter((term) => term.type === 'function')
        .reduce((acc, term) => {
          acc[term.name] = term.arguments;
          return acc;
        }, {}),
    [thresholdTerms],
  );

  const listRef = useCallback((node) => {
    const scrollbar = node || {};
    if (!scrollbar?.contentEl?.firstChild) {
      scrollBarRef.current = null;
      setListContainer(null);
      return;
    }

    scrollbar.isVisible = (elem) => isVisible(scrollbar, elem);

    scrollBarRef.current = scrollbar;
    setListContainer(scrollbar.contentEl.firstChild);

    const height = scrollbar.containerEl?.clientHeight || 0;
    const childHeight =
      scrollbar.contentEl.firstChild?.firstChild?.clientHeight || 1;

    setPageSize(Math.floor(height / childHeight));
  }, []);

  const scrollToChildIndex = useCallback((node, index) => {
    if (!scrollBarRef.current) {
      return;
    }

    const child = node?.children?.[index];

    if (child) {
      preventMouseEnter.current = true;
      scrollBarRef.current.scrollIntoView(child, {
        onlyScrollIfNeeded: true,
      });
    }
  }, []);

  const {
    onKeyDown: listOnKeyDown,
    focusIndex,
    setFocusIndex,
  } = useListKeyboardNavigation(listContainer, {
    pageSize,
    skipHomeEnd: true,
    scrollToChildIndex,
  });

  const [suggestStatus, setSuggestStatus] = useState(FetchStates.init);
  useDebounce(() => setSuggestStatus(suggestionsStatus), 1000, [
    suggestionsStatus,
  ]);

  /** METHODS */

  const showMenu = useCallback(() => {
    if (!textAreaRef.current) {
      return;
    }

    const textAreaPos = textAreaRef.current.getBoundingClientRect();
    const relCaretPos = getCaretCoords(
      textAreaRef.current,
      textAreaRef.current.selectionEnd,
    );
    const x = Math.round(textAreaPos.left + relCaretPos.left);
    const y = Math.round(textAreaPos.top + relCaretPos.top);
    showMenuXY(x, y + 20);
  }, []);

  const fetchSuggestions = useCallback(
    (force = false) => {
      // Get absolute position of caret
      if (!textAreaRef.current) {
        return;
      }

      // Ignore text selections
      const {
        selectionEnd,
        selectionStart: caretPos,
        value: textValue,
      } = textAreaRef.current;
      if (selectionEnd !== caretPos) {
        return;
      }

      if (force) {
        showMenu();
        dispatch(actions.cancel(namespaceId));
        dispatch(
          actions.fetchSuggestions(
            { id: namespaceId, text: textValue, caretPos, fieldType: context },
            namespaceId,
          ),
        );
      } else {
        onChangeDebouncedFetchSuggestions(
          dispatch,
          textValue,
          caretPos,
          context,
        );
      }
    },
    [showMenu, context, namespaceId],
  );

  const insertSuggestion = useCallback(
    (suggested, start, stop, quote = true) => {
      const { value: suggest, token, category } = suggested;
      mutableState.shouldSetCaret = true;

      const injection = quote ? nqlLang.quote(suggest) : suggest;

      let brackets = '';

      if (category === 'function') {
        brackets = '()';
      }

      if (token === ')') {
        brackets += token;
      }

      // 3 - because after nqlLang.quote we have addition 2 symbols quotes
      let caretPos = start + suggest.length + (injection !== suggest ? 3 : 1);

      if (suggested.arguments != null) {
        if (suggested.arguments < 1) {
          caretPos += 2;
        }
      } else if (token === ')') {
        caretPos += 1;
      }

      setText((prev) => {
        let needSpaceBefore = false;
        let offsetEnd = 1;
        let offsetStart = 0;
        let replaced = prev.slice(start, stop + offsetEnd);
        const currentPos = textAreaRef.current.selectionStart;
        let left = prev.slice(0, currentPos);
        let right = prev.slice(currentPos);

        const lastIsBracket = left.endsWith('(');

        switch (true) {
          case /^[\s\n]+$/g.test(replaced) && !lastIsBracket:
            left = replaced.slice(0, currentPos - start);
            right = prev.slice(currentPos, stop + offsetEnd);

            replaced = '';

            if (left.endsWith('\n')) {
              offsetStart = left.length;
              caretPos += offsetStart;
            } else {
              replaced = start ? ' ' : '';
              replaced = left.includes('\n') ? left : replaced;
              caretPos += replaced.length;
            }

            offsetEnd = currentPos + right.indexOf('\n') - stop;
            needSpaceBefore = true;
            break;
          case replaced === ')' && brackets === ')':
            replaced = '';
            brackets = ' )';
            if (thresholdSet.has(context)) {
              [replaced] = left.match(/(\w+)\([^)]*$/g) || [];
              if (replaced) {
                replaced = replaced.split('(');
                let args = thresholdFunctions[replaced[0]] || 0;
                replaced = replaced[1].split(',').length;
                args -= replaced;
                brackets = args < 1 ? ') ' : ', )';
                caretPos += 1;
              }
              replaced = '';
            }
            caretPos -= 1;
            break;
          case lastIsBracket:
            replaced = '';
            break;
          default:
            replaced = whiteSpaceRegex.test(replaced) ? ' ' : '';

            if (replaced === ' ') {
              offsetEnd = 0;
              caretPos += 1;
              needSpaceBefore = true;
            }
            break;
        }

        const prefix = `${prev.slice(0, start + offsetStart)}${replaced}`;

        let suffix = prev.slice(stop + offsetEnd, prev.length);

        const space = injection.endsWith(':') ? '' : ' ';

        suffix = suffix
          ? `${needSpaceBefore ? space : ''}${suffix}`
          : `${suffix}${space}`;

        mutableState.caretPos = caretPos;

        return `${prefix}${injection}${brackets}${suffix}`;
      });

      setMenuItems([]);
    },
    [context, thresholdFunctions],
  );

  const replaceText = useCallback(
    (_text) => {
      setText(_text);
      setMenuItems([]);
      fetchSuggestions();
      hideMenu();
    },
    [hideMenu, fetchSuggestions],
  );

  const onNQLPress = useEvent((_text) => {
    const item = menuItems?.[focusIndex];
    if (!item) {
      return;
    }

    let stop = item.replaceEnd;
    if (text && text.length > stop + 1) {
      let temp = text.slice(stop + 1);
      temp =
        temp
          .match(/('.*?')|(".*?")/g)
          ?.reduce(
            (acc, cur) =>
              acc.replace(cur, new Array(cur.length).fill('_').join('')),
            temp,
          ) || temp;
      const match = temp.match(/&&|\|\||(\b\s+(and|or))|\)|$/i);
      if (match) {
        stop += match.index - (match[0].length && match[0][0] !== ' ');
      }
    }

    insertSuggestion(
      { ...item, category: 'field', value: _text },
      item.replaceStart,
      stop,
      false,
    );
    hideMenu();
  });

  const onBlur = useEvent((event) => {
    setIsFocused(false);
    const formatted = nqlLang.format(text);
    if (text && formatted !== text) {
      onSubmit?.(formatted);
    }
    tail?.onBlur?.(event);
  });

  const onChooseItem = useCallback(
    (item, event) => {
      replaceText(item.nql);
      onSubmit?.(item.nql);
      onBlur(event);
      onPresetItemClick?.(item);
    },
    [replaceText, onPresetItemClick, onBlur],
  );

  const onType = useCallback((event) => {
    const input = event.target.value;
    setText(input);
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      listOnKeyDown(event);

      if (show) {
        switch (event.key) {
          case 'Tab':
            event.preventDefault();
            break;
          case 'Enter':
            // Only put new line if doing Shift + Enter
            if (event.shiftKey) {
              break;
            }
            event.preventDefault();
            break;
          case 'Escape':
            break;
          default:
            break;
        }

        return;
      }

      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowUp':
          if (menuItems?.length) {
            event.preventDefault();
            showMenu();
            setFocusIndex(0);
          }
          break;
        case 'Home':
          if (event.shiftKey) {
            break;
          }
          event.preventDefault();
          textAreaRef.current?.setSelectionRange(0, 0);
          break;
        case 'End':
          if (event.shiftKey) {
            break;
          }
          event.preventDefault();
          textAreaRef.current?.setSelectionRange(text.length, text.length);
          break;
        case 'Enter':
          // Only put new line if doing Shift + Enter
          if (event.shiftKey) {
            break;
          }
          event.preventDefault();
          break;
        case ' ':
          if (event.ctrlKey) {
            fetchSuggestions(true);
          }
          break;
        case '@':
          // evt.preventDefault();
          // showMenu();
          break;
        default:
          break;
      }
    },
    [text, show, listOnKeyDown, fetchSuggestions, menuItems],
  );

  const onKeyUp = useCallback(
    (event) => {
      const selectedItem = focusIndex === -1 ? null : menuItems[focusIndex];

      switch (event.key) {
        case 'Tab':
          event.preventDefault();
          if (!selectedItem) {
            break;
          }

          insertSuggestion(
            selectedItem,
            selectedItem.replaceStart,
            selectedItem.replaceEnd,
          );
          hideMenu();
          break;
        case 'Enter':
          event.preventDefault();

          // user inserting new line
          if (event.shiftKey) {
            if (show) {
              setFocusIndex(0);
              fetchSuggestions();
            }
            break;
          }

          if (show) {
            event.stopPropagation();
            if (selectedItem) {
              insertSuggestion(
                selectedItem,
                selectedItem.replaceStart,
                selectedItem.replaceEnd,
              );
            }
            hideMenu();
            break;
          }

          onSubmit?.(text, event);

          onEnterPress?.(event);
          break;
        case 'Escape':
          if (show) {
            event.stopPropagation();
            hideMenu();
            break;
          }

          if (doubleEscapeToRollback) {
            event.stopPropagation();
            const _searchedNQL =
              (Array.isArray(searchedNQL) ? searchedNQL[0] : searchedNQL) || '';
            setText(_searchedNQL);
          }
          break;

        // skipping
        case 'ArrowDown':
        case 'ArrowUp':
        case 'PageDown':
        case 'PageUp':
        case 'Control':
        case 'Alt':
        case 'Shift':
        case 'Meta':
        case 'CapsLock':
          break;

        case 'ArrowLeft':
        case 'ArrowRight':
        default:
          fetchSuggestions();
          break;
      }
    },
    [
      text,
      show,
      hideMenu,
      menuItems,
      focusIndex,
      searchedNQL,
      insertSuggestion,
      fetchSuggestions,
      doubleEscapeToRollback,
      onEnterPress,
    ],
  );

  const onMouseUp = useCallback(() => {
    if (!show && menuItems?.length) {
      showMenu();
    }

    fetchSuggestions();
  }, [fetchSuggestions, show, menuItems]);

  const onFocus = useEvent((event) => {
    setIsFocused(true);
    if (!readOnly) {
      fetchSuggestions();
    }
    tail?.onFocus?.(event);
  });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();

      const index = event.target?.dataset?.index;
      const item = menuItems[index];

      if (!item) {
        return;
      }

      insertSuggestion(item, item.replaceStart, item.replaceEnd);
      hideMenu();
    },
    [menuItems, insertSuggestion],
  );

  const onMouseMove = useCallback((event) => {
    if (!preventMouseEnter.current) {
      return;
    }

    preventMouseEnter.current = false;

    const index = event.target?.dataset?.index;
    if (index != null) {
      setFocusIndex(+index);
    }
  }, []);

  const onMouseEnter = useCallback((event) => {
    if (preventMouseEnter.current) {
      return;
    }

    const index = event.target?.dataset?.index ?? '0';
    setFocusIndex(+index);
  }, []);

  /** EFFECTS */

  useEffect(() => {
    // update text only if value is new and doesn't equal to text.
    if (lastValue.current !== value) {
      setText(value);
    }
  }, [value]);

  const doSubmit = useMemo(
    () =>
      debounce((val) => {
        lastValue.current = val;
        onSubmit?.(val);
      }, 300),
    [onSubmit],
  );

  const firstRender = useRef(true);
  useEffect(() => {
    if (mutableState.shouldSetCaret) {
      textAreaRef.current?.setSelectionRange(
        mutableState.caretPos,
        mutableState.caretPos,
      );
      mutableState.shouldSetCaret = false;
      fetchSuggestions();
    }

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    doSubmit(text);
  }, [text, fetchSuggestions]);

  useEffect(() => {
    if (suggestionsStatus !== FetchStates.success) {
      if (suggestionsStatus === FetchStates.error) {
        setMenuItems([]);
        hideMenu();
      }
      return;
    }

    const { suggestions, suggestionType, replaceStart, replaceEnd, token } =
      suggestionsData || {};

    let prepared = suggestions;

    switch (suggestionType) {
      case 'TAGS':
        prepared = Array.from(
          suggestions.reduce((acc, item) => {
            let name = item;

            if (token.includes(':')) {
              acc.add(name);
              return acc;
            }

            if (name.includes(':')) {
              name = `${name.split(':')[0]}:`;
            }

            acc.add(name);

            return acc;
          }, new Set()),
        );
        break;
      case 'FIELDS_OR_FUNCTIONS':
      case 'EXPR_FIELDS_OR_FUNCTIONS':
      case 'FIELD':
      case 'FLOW_FIELDS':
        // excluding everything field
        if (excludingEverythingField) {
          prepared = prepared?.filter((item) => item.name !== 'everything');
        }

        // WARNING: do not change order of conditions in the code! order is important
        if (!startsWithLowerCase(token, 'label.')) {
          prepared = prepared.filter(
            (item) => !/^label(\.(.*?)){2,3}$/gi.test(item.name),
          );
          break;
        }

        // label.
        if (/^label\.$/gi.test(token)) {
          prepared = prepared.filter(
            (item) =>
              startsWithLowerCase(item.name, token) &&
              !/^label(\.(.*?)){2,3}$/gi.test(item.name),
          );
          break;
        }

        // label.ip.name
        if (/^label(\.(.*?)){2}.$/gi.test(token)) {
          prepared = prepared.filter((item) =>
            startsWithLowerCase(item.name, token),
          );
          break;
        }

        // label.ip
        if (/^label\.(.*?)$/gi.test(token)) {
          prepared = prepared.filter(
            (item) =>
              startsWithLowerCase(item.name, token) &&
              !/^label(\.(.*?)){3}$/gi.test(item.name),
          );
          break;
        }
        break;
      default:
        break;
    }

    if (isFocused === true && prepared?.length) {
      const items = (prepared || []).map((item) => {
        const label = item.name || item;
        const doc = applyDocTemplate(
          {
            ...item,
            doc:
              item.doc ||
              (suggestionType === 'BITWISE_OR_CMP'
                ? docs?.docs?.operators?.all?.[label].doc
                : docs?.docs?.fields?.[context]?.[label]?.doc) ||
              templates.doc,
          },
          docTemplateConfig,
        );
        return {
          label,
          replaceStart,
          replaceEnd,
          alias: item.alias,
          category: item.category,
          arguments: item.arguments,
          id: item.id,
          token,
          doc,
          value: item.value || label,
          trafficType: item.traffictype,
        };
      });

      setMenuItems(items);
      setFocusIndex(0);
      showMenu();

      return;
    }

    setMenuItems([]);
    hideMenu();
  }, [
    suggestionsData,
    suggestionsStatus,
    isFocused,
    docs,
    showMenu,
    docTemplateConfig,
  ]);

  useEffect(() => {
    if (isFocused && !readOnly && suggestStatus === FetchStates.fetching) {
      setMenuItems([]);
      showMenu();
    }
  }, [suggestStatus, isFocused, readOnly]);

  useEffect(
    () => () => {
      dispatch(actions.clearSuggestions(namespaceId));
    },
    [namespaceId],
  );

  const AutocompleteMenu = useMemo(() => {
    const menuItem = focusIndex === -1 ? null : menuItems[focusIndex];
    const panelContent =
      menuItem?.doc && !/^\s*$/.test(menuItem.doc) ? (
        <DocumentationPanel
          sourceDoc={menuItem.doc}
          onMouseDown={stopFocusLoss}
          onNQLPress={onNQLPress}
        />
      ) : undefined;
    const isThreshold = thresholdSet.has(context);

    let search = '';

    const {
      selectionStart,
      selectionEnd,
      value: textValue = '',
    } = textAreaRef.current || {};

    if (selectionStart === selectionEnd) {
      search = textValue.slice(0, selectionStart);
      [search = ''] = search.match(/([.\w:-]+|[!=><^&|]+)$/) || [];
    }

    return (
      <NQLMenu
        panelContent={panelContent}
        animated={false}
        hideOnClickOutside={false}
        avoidMouseDownAction
      >
        <ScrollBar ref={listRef}>
          {suggestStatus === FetchStates.fetching ? (
            <MenuLoader length={11} />
          ) : (
            menuItems.map((item, i) => (
              <AutocompleteItem
                key={`${item.id}_${item.label}_${item.category || ''}`}
                data-index={i}
                focused={focusIndex === i}
                onMouseMove={onMouseMove}
                onMouseEnter={onMouseEnter}
                onMouseDown={stopFocusLoss}
                onClick={item.onClick || onClick}
                $isIconShown={
                  item.trafficType ||
                  context === ContextTypes.traffic ||
                  item.icon ||
                  (isThreshold && item.category !== 'operator')
                }
                dismissOnExecute={false}
                hoverFocus={false}
              >
                {item.icon && <Icon>{item.icon}</Icon>}
                {item.trafficType && item.trafficType.length < 2 && (
                  <Icon $context={item.trafficType}>{item.trafficType}</Icon>
                )}
                {isThreshold && item.category === 'function' && (
                  <Icon>
                    <FunctionVariantIcon size={14} />
                  </Icon>
                )}
                <Highlight search={search}>{item.label}</Highlight>
                {item.category === 'function' && (
                  <ItemMenuSuffix>
                    (
                    {new Array(item.arguments || 0)
                      .fill(isCountFunction(item.label) ? 'field' : 'metric')
                      .join(', ')}
                    )
                  </ItemMenuSuffix>
                )}
                {item.alias ? ' @' : ''}
              </AutocompleteItem>
            ))
          )}
        </ScrollBar>
      </NQLMenu>
    );
  }, [menuItems, focusIndex, suggestStatus, docStatus]);

  const fixedCode = (text || '').split('\n').at(-1) === '' ? `${text} ` : text;

  // TextArea component must be first and only then must be Dropdown
  // If Dropdown will be first then Dropdown will be `hovered` when label is hovered
  // This `label hovered` issue can be caught in Global Filters form
  return (
    <Container
      ref={containerRef}
      className={classNames('nql-main-container', { disabled, invalid })}
      $activeBorder={isFocused || dropdownOpen}
      data-tracking={tail['data-tracking']}
    >
      {AutocompleteMenu}

      <CodeBlock className="nql-code-block">{fixedCode}</CodeBlock>

      <TextArea
        ref={textAreaRef}
        id={tail.id}
        name={tail.name}
        className={classNames('nql-textarea', { invalid })}
        placeholder={tail.placeholder}
        value={text}
        onChange={onType}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseUp={readOnly ? null : onMouseUp}
        onKeyDown={readOnly ? null : onKeyDown}
        onKeyUp={readOnly ? null : onKeyUp}
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        disabled={disabled}
        readOnly={readOnly}
        $hasPresets={allowPresets}
      />

      {allowPresets && (
        <Dropdown
          nql={text}
          invalid={invalid}
          disabled={disabled}
          readOnly={readOnly}
          context={context}
          allowRecents={allowRecents}
          onChooseItem={onChooseItem}
          onOpenOrClose={setDropdownOpen}
          containerWidth={containerRef?.current?.offsetWidth || 450}
          presetsLeftOffset={presetsLeftOffset}
          presetsWidthExtension={presetsWidthExtension}
        />
      )}
    </Container>
  );
});

NQLTextArea.propTypes = {
  value: PropTypes.string,
  /**
   * nql context
   */
  context: PropTypes.oneOfContext(),
  docTemplateConfig: PropTypes.shape({
    showLabel: PropTypes.bool,
    showDescription: PropTypes.bool,
    showInfo: PropTypes.bool,
    showExample: PropTypes.bool,
    showIntersectable: PropTypes.bool,
  }),
  doubleEscapeToRollback: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  onEnterPress: PropTypes.func,
  onPresetItemClick: PropTypes.func,
  allowPresets: PropTypes.bool,
  allowRecents: PropTypes.bool,
  presetsLeftOffset: PropTypes.string,
  presetsWidthExtension: PropTypes.number,
  excludingEverythingField: PropTypes.bool,
};

NQLTextArea.defaultProps = {
  value: '',
  context: ContextTypes.flow,
  docTemplateConfig: defaultDocTemplateConfig,
  doubleEscapeToRollback: false,
  invalid: false,
  disabled: false,
  readOnly: false,
  onSubmit: null,
  onEnterPress: null,
  onPresetItemClick: null,
  allowPresets: true,
  allowRecents: true,
  presetsLeftOffset: '-8px',
  presetsWidthExtension: 0,
  excludingEverythingField: true,
};

export default NQLTextArea;
