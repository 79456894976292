import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import CheckboxBlankCircleIcon from 'mdi-react/CheckboxBlankCircleIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseBoxIcon from 'mdi-react/CloseBoxIcon';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as wizardActions,
  selectors as wizardSelectors,
} from '@/redux/ui/wizard';

import Button from '+components/Button';
import { Col, Row } from '+components/Layout';
import usePermissions from '+hooks/usePermissions';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.noDevicesWrapper};
  overflow: hidden;
  z-index: 700;
  background-color: ${(p) => p.theme.colorBackgroundBody};

  @media screen and (max-width: 1280px) {
    justify-content: flex-start;
  }
`;

const Container = styled(Row)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: fit-content;
`;

const ColCenterAlign = styled(Col)`
  text-align: center;
`;

const StepsContainer = styled(Row)`
  margin-top: 25px;
  justify-content: center;
  flex-wrap: nowrap;
`;

const Step = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 200px;
  width: 200px;

  position: relative;

  border: 1px solid ${(p) => p.theme.toggleFocusBorderColor};
  margin-right: 25px;

  padding: 20px 5px;

  &:last-child {
    margin-right: 0;
  }

  background: ${(props) => props.theme.noDevicesStepBackground};
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 35px;
  font-weight: 400;

  color: ${(props) => props.theme.noDevicesTitle};
`;

const SubTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;

  color: ${(props) => props.theme.noDevicesTitle};
`;

const CheckboxMarked = styled(CheckboxMarkedCircleIcon)`
  color: ${({ theme }) => theme.primary};
  position: absolute;
  top: 0;
  left: 0;
`;

const CheckboxBlank = styled(CheckboxBlankCircleIcon)`
  position: absolute;
  top: 2px;
  left: 2px;
`;

const Done = styled(({ className }) => (
  <div className={className}>
    <CheckboxBlank size={40} />
    <CheckboxMarked size={46} />
  </div>
))`
  position: absolute;
  width: 46px;
  height: 46px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.noDevicesStepBackground};
`;

const StepTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const TitleFirst = styled(StepTitle)`
  color: ${(props) => props.theme.noDevicesStepFirst};
`;

const TitleSecond = styled(StepTitle)`
  color: ${(props) => props.theme.noDevicesStepSecond};
`;

const StepSubTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.noDevicesText};
  height: 45px;
  flex-grow: 1;
`;

const Dismiss = styled.button`
  color: ${({ theme }) => theme.primary};
  position: absolute;
  top: -13px;
  right: -2px;
  background: transparent;
  border: 0;
  outline: 0;
  padding: unset;
`;

const RowDismiss = styled(Row)`
  position: relative;
`;

const ImgContainer = styled.div`
  display: flex;
  padding: 5px 15px 15px 15px;
`;

const StepImg = styled.img`
  width: 70px;
  height: 60px;
`;

const BlockSpan = styled.span`
  line-height: 1.2;
  font-size: ${(p) => p.$fontsize ?? '14px'};
  display: block;
  color: ${(p) =>
    p.$textColor
      ? p.theme.toggleFocusBorderColor
      : p.theme.palette.text.secondary} !important;
`;

// toggleFocusBorderColor
const Wizard = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stepFirst = useSelector(wizardSelectors.getFirstStep);
  const stepSecond = useSelector(wizardSelectors.getSecondStep);
  const collectorIp = useSelector(customerSelectors.getCollectorIp);
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const permissionsDevice = usePermissions(
    PermissionModel.Resources.device.value,
  );

  const message = useMemo(() => {
    if (stepFirst) {
      return 'No Flow Received';
    }

    return `No ${isDnsEnabled ? 'Traffic' : 'Flow'} Sources Configured`;
  }, [stepFirst, isDnsEnabled]);

  const onClick = useCallback(() => {
    dispatch(wizardActions.dismiss());
  }, []);

  return (
    <Wrapper className={className}>
      <Container>
        <RowDismiss>
          <ColCenterAlign>
            <Title>{message}</Title>
            <SubTitle>Getting started is easy</SubTitle>
          </ColCenterAlign>
          <Dismiss onClick={onClick}>
            <CloseBoxIcon />
          </Dismiss>
        </RowDismiss>

        <StepsContainer>
          <Step>
            {stepFirst && <Done />}
            <TitleFirst>Step 1</TitleFirst>
            <StepSubTitle>
              Add {isDnsEnabled ? 'Traffic' : 'Flow'} source
            </StepSubTitle>
            <ImgContainer>
              <StepImg src="/assets/graphic-flow-sources.png" alt="" />
            </ImgContainer>
            {permissionsDevice?.create && (
              <Button onClick={() => navigate(`${RoutePaths.sources}`)}>
                Add Source
              </Button>
            )}
          </Step>

          <Step>
            {stepSecond && <Done />}
            <TitleSecond>Step 2</TitleSecond>
            <StepSubTitle>Send your flow</StepSubTitle>
            <div>
              <BlockSpan $fontsize="12px">Your Collector IP:</BlockSpan>
              <BlockSpan $textColor>{collectorIp}</BlockSpan>
            </div>
          </Step>
        </StepsContainer>
      </Container>
    </Wrapper>
  );
};

Wizard.propTypes = {
  className: PropTypes.string,
};

Wizard.defaultProps = {
  className: null,
};

export default Wizard;
