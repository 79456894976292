import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import { Settings } from 'luxon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { createSelector, createSlice } from '@/redux/util';

import authClient from '@/middleware/authClient';

import dayjs from '+utils/dayjs';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

dayjs.locale('en');

const defaultPeriod = TimePeriods[TimeDuration.minute];

const keyStore = 'globalFilters';

const version = 6;
const utcTz = 'Etc/UTC';

const timeLag = 60e3; // ms

const allowedContexts = [
  ContextTypes.flow,
  ContextTypes.dns,
  ContextTypes.traffic,
  ContextTypes.alerts,
  ContextTypes.blocks,
];

const defaultState = {
  version,
  filters: {
    period: {
      type: TimeDuration.hour,
      value: 4,
    },
    dateTimeMode: DateTimeModes.range,
    from: Date.now() - defaultPeriod.period,
    to: Date.now(),
    startIsMin: false,
    endIsNow: false,
    tz: utcTz,
    utc: true,
    realtime: true,
    context: ContextTypes.flow,
    // TODO: Move metric, nql and intersect to one object with [context] fields
    metricFlow: 'bitrate',
    metricAlerts: 'alertrate',
    metricBlocks: 'blockrate',
    metricDns: 'queries',
    metricTraffic: 'bitrate',
    nqlFlow: [],
    nqlAlerts: [],
    nqlBlocks: [],
    intersectFlow: [],
    intersectAlerts: [],
    intersectBlocks: [],
    labelContext: { show: true, ip: 'name', port: 'name' },
    customers: [],
  },
  exports: {
    all: true,
  },
};

let loaded;
if (!authClient.isGuest()) {
  try {
    const loadString = localStorage.getItem(keyStore);
    loaded = loadString ? JSON.parse(loadString) : null;
    // to support old saved filters
    Object.entries(loaded?.filters || {}).forEach(([key, value]) => {
      if (key.startsWith('nql') && !Array.isArray(value)) {
        loaded.filters[key] = [value];
      }
    });
  } catch (e) {
    loaded = null;
  }
}

const calcFromAndToByPeriod = (value) => {
  const { period = 0 } = TimePeriods[value.type] || {};
  const rate = Math.max(1, value.value || 1);

  const to = Date.now();
  const from = to - period * rate;

  return { from, to };
};

const calcActualDateTimeMode = (filters) => {
  const { period, endIsNow, realtime, dateTimeMode } = filters;

  let mode = DateTimeModes.realtime;

  if (!realtime || dateTimeMode !== DateTimeModes.realtime) {
    if (period?.type === CustomType) {
      mode = DateTimeModes.range;

      if (endIsNow) {
        mode = DateTimeModes.now;
      }
    } else {
      mode = DateTimeModes.now;
    }
  }

  return mode;
};

const saveState = (state) => {
  state.filters.actualDateTimeMode = calcActualDateTimeMode(state.filters);

  if (!authClient.isGuest()) {
    const string = JSON.stringify(state);
    localStorage.setItem(keyStore, string);
  }
};

const initialState = {
  ...defaultState,
  filters: {
    ...defaultState.filters,
    period: { ...defaultState.filters.period },
    // eslint-disable-next-line no-mixed-operators
    ...((version === +(loaded?.version || 0) && loaded?.filters) || {}),
  },
  exports: {
    ...defaultState.exports,
    // eslint-disable-next-line no-mixed-operators
    ...((version === +(loaded?.version || 0) && loaded?.exports) || {}),
  },
};

if (!ContextTypes[initialState.filters.context]) {
  initialState.filters.context = ContextTypes.flow;
}

dayjs.tz.setDefault(initialState.filters.tz);
Settings.defaultZone = initialState.filters.tz;
Settings.defaultLocale = 'en-US';

if (initialState.filters.period.type !== CustomType) {
  initialState.filters = {
    ...initialState.filters,
    period: { ...initialState.filters.period },
    ...calcFromAndToByPeriod(initialState.filters.period),
  };
  saveState(initialState);
}

const slice = createSlice({
  name: keyStore,
  initialState,

  reducers: {
    changeFilter(state, { payload }) {
      if (!payload) {
        return;
      }

      const prevPeriod = state.filters?.period || {};

      const period = payload.period
        ? {
            ...prevPeriod,
            ...(payload.period || {}),
          }
        : prevPeriod;

      const override = { period };

      if (payload.period && period.type !== CustomType) {
        const { from, to } = calcFromAndToByPeriod(payload.period);
        override.to = to;
        override.from = from;
      }

      if (payload.from) {
        override.from = +payload.from;
      }

      if (payload.to) {
        override.to = +payload.to;
      }

      if ('tz' in payload) {
        dayjs.tz.setDefault(payload?.tz || utcTz);
        Settings.defaultZone = payload?.tz || utcTz;
        override.utc = payload?.tz === utcTz;
      }

      if (payload.context && !ContextTypes[payload.context]) {
        payload.context = ContextTypes.flow;
      }

      state.filters = {
        ...(state?.filters || {}),
        ...payload,
        ...override,
      };
      saveState(state);
    },

    setMetric(state, { payload: { metric, context } }) {
      const fixedContext = context || state.filters.context;
      if (!ContextTypes[fixedContext]) {
        return;
      }

      const metricField = getMetricFieldName(fixedContext);
      state.filters[metricField] = metric;
      saveState(state);
    },

    setLabelContext(state, { payload: labelContext }) {
      state.filters.labelContext.show = labelContext.show;
      state.filters.labelContext.ip = labelContext.ip;
      state.filters.labelContext.port = labelContext.port;
      saveState(state);
    },

    refreshDateTime(state, { payload: dateTimeLimit }) {
      const now = Date.now();
      const isMin = state.filters.startIsMin;
      const isNow = state.filters.endIsNow;
      const isCustom = state.filters.period.type === CustomType;

      if (isCustom && !isMin && !isNow) {
        return;
      }

      const next = isCustom
        ? {
            from: isMin
              ? now + timeLag - TimeDuration.day * dateTimeLimit
              : state.filters.from,
            to: isNow ? now : state.filters.to,
          }
        : calcFromAndToByPeriod(state.filters.period);

      state.filters.from = next.from;
      state.filters.to = next.to;

      saveState(state);
    },

    reset(state, { payload }) {
      const {
        context,
        resetAllPortalFields = true,
        resetOnlyThesePortalFields = [],
      } = payload || {};

      const period = { ...defaultState.filters.period };
      const labelContext = { ...defaultState.filters.labelContext };

      const skipFields = !context
        ? []
        : allowedContexts.reduce((acc, item) => {
            if (item === context) {
              return acc;
            }
            const metricFieldName = getMetricFieldName(item);
            const nqlFieldName = getNqlFieldName(item);
            const intersectFieldName = getIntersectFieldName(item);
            return [...acc, metricFieldName, nqlFieldName, intersectFieldName];
          }, []);

      state.filters = {
        ...(!resetAllPortalFields
          ? omit(state.filters, resetOnlyThesePortalFields)
          : {}),
        ...omit(defaultState.filters, skipFields),
        ...skipFields.reduce(
          (acc, item) => ({
            ...acc,
            [item]: state.filters[item],
          }),
          {},
        ),
        dateTimeMode: state.filters.dateTimeMode,
        period: { ...period },
        context: context || state.filters.context,
        utc: state.filters.utc,
        tz: state.filters.tz,
        ...calcFromAndToByPeriod(period),
        labelContext: { ...labelContext },
      };
      saveState(state);
    },
  },

  sagas: () => ({}),

  selectors: (selector) => {
    /**
     * @param {ContextTypes?} context
     * @return {function(state): boolean}
     */
    const isFiltered =
      (context = ContextTypes.flow) =>
      (state) => {
        const { filters } = slice.selector(state);
        const { filters: defaultFilters } = defaultState;

        const metricFieldName = getMetricFieldName(context);
        const nqlFieldName = getNqlFieldName(context);
        const intersectFieldName = getIntersectFieldName(context);

        const metric = filters[metricFieldName];
        const metricDef = defaultFilters[metricFieldName];

        const nql = (
          filters[nqlFieldName] || defaultFilters[nqlFieldName]
        )?.filter(Boolean);
        const nqlDef = defaultFilters[nqlFieldName]?.filter(Boolean);

        const intersect = (
          filters[intersectFieldName] || defaultFilters[intersectFieldName]
        )?.filter(Boolean);
        const intersectDef =
          defaultFilters[intersectFieldName]?.filter(Boolean);

        return (
          metric !== metricDef ||
          !isEqual(nql, nqlDef) ||
          !isEqual(intersect, intersectDef)
        );
      };

    /**
     * @param {ContextTypes?} context
     * @return {function(state): boolean}
     */
    const isFilteredAll = (context) => (state) => {
      const { filters } = selector(state);
      const { filters: defaultFilters } = defaultState;
      if (
        filters.period.type !== defaultFilters.period.type ||
        filters.period.value !== defaultFilters.period.value ||
        (filters.dateTimeMode === DateTimeModes.realtime &&
          filters.realtime !== defaultFilters.realtime)
      ) {
        return true;
      }
      if (
        filters.labelContext.show !== defaultFilters.labelContext.show ||
        filters.labelContext.ip !== defaultFilters.labelContext.ip ||
        filters.labelContext.port !== defaultFilters.labelContext.port
      ) {
        return true;
      }
      if (!isEqual(filters.customers, defaultFilters.customers)) {
        return true;
      }
      if (context) {
        return isFiltered(context)(state);
      }
      return allowedContexts.some((item) => isFiltered(item)(state));
    };

    const getDefaultFilters = createSelector(
      [selector],
      () => defaultState.filters,
    );

    const getFilters = createSelector([selector], (state) => state.filters);

    const getContext = createSelector([getFilters], (state) => state.context);

    const getQueryFilter = (context) =>
      createSelector(
        [getFilters, getContext],
        (filters, innerContext) =>
          filters[getNqlFieldName(context || innerContext)],
      );

    return {
      isFiltered,
      isFilteredAll,
      getDefaultFilters,
      getFilters,
      getContext,
      getQueryFilter,
    };
  },
});

export const { selectors, actions } = slice;

export {
  defaultState,
  allowedContexts,
  calcFromAndToByPeriod,
  calcActualDateTimeMode,
};

export default slice;
