import { Fragment, useMemo, useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import isEqual from 'lodash.isequal';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import FeatureFlags from '@/models/FeatureFlags';

import { lang } from '+components/charts/common/utils';
import {
  TrafficColumns,
  trafficDefaultColumns,
  TrafficTable,
} from '+components/ContextTables';
import DnsTable, {
  Columns as dnsTableColumns,
  defaultColumns as dnsTableDefaultColumns,
} from '+components/ContextTables/DnsTable';
import FlowTable, {
  Columns as flowTableColumns,
  defaultColumns as flowTableDefaultColumns,
} from '+components/ContextTables/FlowTable';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import RealtimeManager from '+components/RealtimeManager';
import useEvent from '+hooks/useEvent';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';

const excludeContexts = new Set([ContextTypes.alerts, ContextTypes.blocks]);

const defaultTableValues = {
  throttle: {
    wait: 2000,
    props: ['tableData', 'noDataText'],
  },
};

const FlowTableRealtime = RealtimeManager(FlowTable, defaultTableValues);
const DnsTableRealTime = RealtimeManager(DnsTable, defaultTableValues);
const TrafficTableRealTime = RealtimeManager(TrafficTable, defaultTableValues);

const TableComponents = {
  [ContextTypes.flow]: FlowTableRealtime,
  [ContextTypes.dns]: DnsTableRealTime,
  [ContextTypes.traffic]: TrafficTableRealTime,
};

const tableColumns = {
  [ContextTypes.flow]: flowTableDefaultColumns.filter(
    (item) => item !== flowTableColumns.timestamp,
  ),
  [ContextTypes.dns]: dnsTableDefaultColumns.filter(
    (item) => item !== dnsTableColumns.timestamp,
  ),
  [ContextTypes.traffic]: trafficDefaultColumns.filter(
    (item) => item !== TrafficColumns.timestamp,
  ),
};

const RealTimeTraffic = () => {
  const [flowIncludeFields, setFlowIncludeFields] = useState([]);
  const [dnsIncludeFields, setDnsIncludeFields] = useState([]);
  const [trafficIncludeFields, setTrafficIncludeFields] = useState([]);
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const excluded = useMemo(() => {
    return isDnsEnabled
      ? excludeContexts
      : new Set([...excludeContexts, ContextTypes.traffic]);
  }, [isDnsEnabled]);

  const excludeContextsArr = useMemo(() => Array.from(excluded), [excluded]);

  const context = useLastAllowedContext({
    excludeContexts: excluded,
    defaultContext: ContextTypes.flow,
  });

  const TableComponent = useMemo(() => TableComponents[context], [context]);

  const includeFields = {
    [ContextTypes.flow]: flowIncludeFields,
    [ContextTypes.dns]: dnsIncludeFields,
    [ContextTypes.traffic]: trafficIncludeFields,
  };

  const includeFieldsSetters = {
    [ContextTypes.flow]: setFlowIncludeFields,
    [ContextTypes.dns]: setDnsIncludeFields,
    [ContextTypes.traffic]: setTrafficIncludeFields,
  };

  const { records } = useRealtimeOrRequest({
    name: `traffic_${context}_tap_data`,
    includeFields: includeFields[context],
    stopRequest: !includeFields[context].length,
    context,
  });

  const data = useMemo(() => records?.toArray()?.reverse() || [], [records]);

  const columnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns, setter) => {
      const fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id - we need it to fetch full record if we need it
      fieldsSet.add('id');
      const nextValue = [...fieldsSet].filter(Boolean);
      setter((prevValue) =>
        isEqual(prevValue, nextValue) ? prevValue : nextValue,
      );
    },
  );

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      columnsChange(
        allColumns,
        hiddenColumns,
        technicalColumns,
        includeFieldsSetters[context],
      );
    },
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql
        context={context}
        dateTimeMode={DateTimeModes.realtime}
        excludeContexts={excludeContextsArr}
        customers
      />
      <TableComponent
        id={`Reports_${context}_Tap`}
        columns={tableColumns[context]}
        tableData={data}
        minRows={20}
        noDataText={records ? undefined : lang.loading}
        onColumnsChange={onColumnsChange}
        exportingAllFields={false}
        fillWithEmptyRows
      />
    </Fragment>
  );
};

export default RealTimeTraffic;
