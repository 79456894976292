import PropTypes from '+prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import { DateTimeSpans } from '@/models/DateTimeSpans';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';
import { actions as globalFiltersUiActions } from '@/redux/globalFilters/ui';

import * as toast from '+components/toast';
import getMetricFieldName from '+utils/getMetricFieldName';

let instance = 0;

const Setting = (props) => {
  const {
    range,
    from,
    to,
    realtime,
    metric,
    nql,
    size,
    context,
    customers,
    socketControl,
    dateTimeMode,
    updateEvery,
    configuring,
    excludeMetrics,
    excludeContexts,
    onlyRealtime,
    dateTimeSpan,
  } = props;

  const dispatch = useDispatch();

  const defaultFilters = useSelector(globalFiltersSelectors.getDefaultFilters);
  const filters = useSelector(globalFiltersSelectors.getFilters);
  const metricField = getMetricFieldName(filters.context);
  const defaultFiltersMetric = defaultFilters[metricField];
  const filtersMetric = filters[metricField];
  const isFiltersMetricExcluded = !!excludeMetrics?.includes(filtersMetric);

  useEffect(() => {
    dispatch(
      globalFiltersUiActions.change({
        range,
        from,
        to,
        realtime,
        nql: nql && context,
        size,
        metric,
        customers,
        socketControl,
        updateEvery,
        configuring,
        excludeMetrics,
        excludeContexts,
        onlyRealtime,
        dateTimeSpan,
      }),
    );
  }, [
    range,
    from,
    to,
    realtime,
    metric,
    nql,
    size,
    updateEvery,
    configuring,
    context,
    customers,
    socketControl,
    excludeMetrics,
    excludeContexts,
    onlyRealtime,
    dateTimeSpan,
  ]);

  useEffect(() => {
    dispatch(
      globalFiltersActions.changeFilter({
        context,
        dateTimeMode,
      }),
    );
  }, [context, dateTimeMode]);

  useEffect(() => {
    if (!instance) {
      dispatch(globalFiltersUiActions.showTopbar());
    }
    instance += 1;

    return () => {
      instance = Math.max(0, instance - 1);

      if (!instance) {
        dispatch(globalFiltersUiActions.disableAll());
        dispatch(globalFiltersUiActions.hideTopbar());
      }
    };
  }, ['hot']);

  useEffect(() => {
    if (isFiltersMetricExcluded) {
      dispatch(
        globalFiltersActions.setMetric({
          metric: defaultFiltersMetric,
        }),
      );
      toast.warn({
        message: `Current metric '${filtersMetric}' not supported on this page.`,
        details: `Metric changed to default '${defaultFiltersMetric}'.`,
      });
    }
  }, [isFiltersMetricExcluded, defaultFiltersMetric, filtersMetric]);

  return null;
};

Setting.propTypes = {
  range: PropTypes.bool,
  from: PropTypes.bool,
  to: PropTypes.bool,
  metric: PropTypes.bool,
  nql: PropTypes.bool,
  size: PropTypes.bool,
  context: PropTypes.oneOf(['', ...Object.values(ContextTypes)]),
  customers: PropTypes.bool,
  socketControl: PropTypes.bool,
  dateTimeMode: PropTypes.oneOf(Object.values(DateTimeModes)),
  updateEvery: PropTypes.number,
  configuring: PropTypes.bool,
  onlyRealtime: PropTypes.bool,
  dateTimeSpan: PropTypes.oneOf([DateTimeSpans.minute, DateTimeSpans.second]),
  realtime: PropTypes.bool,
  excludeMetrics: PropTypes.arrayOf(PropTypes.string),
  excludeContexts: PropTypes.arrayOf(PropTypes.string),
};

Setting.defaultProps = {
  range: true,
  from: true,
  to: true,
  metric: false,
  nql: false,
  size: false,
  customers: false,
  socketControl: true,
  context: ContextTypes.flow,
  dateTimeMode: DateTimeModes.range,
  updateEvery: 0,
  configuring: false,
  onlyRealtime: false,
  dateTimeSpan: DateTimeSpans.minute,
  realtime: false,
  excludeMetrics: null,
  excludeContexts: null,
};

export default Setting;
