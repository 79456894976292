import PropTypes from '+prop-types';
import { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { selectors as globalFiltersUISelectors } from '@/redux/globalFilters/ui';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import Button from '+components/Button';
import {
  DropdownContext,
  Dropdown as DropdownOrigin,
} from '+components/Dropdown';
import Tooltip from '+components/Tooltip';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';
import dayjs, { DateFormat } from '+utils/dayjs';
import { timeBounds } from '+utils/timeBounds';

const Dropdown = styled(DropdownOrigin).attrs({
  showIcon: false,
  menuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    PaperProps: {
      style: {
        transform: 'translateY(1px)',
      },
    },
  },
})`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  height: 36px;
  max-height: 36px;
  width: fit-content;
  border-radius: 18px;
  padding: 0 18px;
  overflow: hidden;
  z-index: 99;

  border: unset;
  background-color: transparent !important;

  text-transform: none !important;

  &.active,
  &:hover {
    background-color: ${globalFiltersTheme.mainRowNqlGroupHoverBackground} !important;
  }

  &[disabled] {
    color: ${({ theme }) => theme.iconButtonInsideComponentsText} !important;
    background-color: transparent !important;
    // cursor: not-allowed !important;
    opacity: 0.3 !important;
    // pointer-events: auto !important;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  overflow: hidden;
  margin-top: -3px;
  &:only-child {
    margin-top: unset;
    * {
      max-height: unset;
    }
  }
`;

const ItemLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const ItemValue = styled.span`
  display: flex;
  align-items: center;
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  max-height: 12px;
  font-weight: normal !important;
`;

const DateTimeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  gap: 15px;
`;

const DateTimeMenuRow = styled.div`
  --field-row-gap: 10px;
  --field-width: 300px;
  --period-value-width: 80px;

  display: flex;
  flex-direction: row;
  gap: var(--field-row-gap);

  .form__form-group {
    margin-bottom: 0 !important;
  }

  .period-value {
    width: var(--period-value-width);
  }

  .period-type {
    width: calc(
      var(--field-width) - var(--period-value-width) - var(--field-row-gap)
    );
  }

  .to,
  .from {
    width: var(--field-width);
  }
`;

const DateTimeMenuLabel = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 80px;
  white-space: nowrap;
`;

const DateTimeMenuDoneButton = () => {
  const { hide } = useContext(DropdownContext);
  return <Button onClick={hide}>Close</Button>;
};

const DateTimeDropdown = (props) => {
  const { values, children, ...tail } = props;

  const isPaused = useSelector(socketControlSelectors.isPaused);
  const onlyRealtime = useSelector(globalFiltersUISelectors.getOnlyRealtime);
  const onlyRealtimeLocal = !isPaused && onlyRealtime;

  let { from, to } = values;
  const period =
    isPaused && values.period?.type !== CustomType
      ? { ...values.period, type: TimeDuration.hour }
      : values.period;

  let format = DateFormat.minute;

  switch (period?.type) {
    case CustomType: {
      const { start, end } = timeBounds(values);
      from = start;
      to = end;
      break;
    }
    default: {
      const { fullFormat = DateFormat.minute } =
        TimePeriods[period?.period] || {};
      format = fullFormat;
      break;
    }
  }

  let fromLabel;
  let toLabel;
  let fromDate;
  let toDate;

  let mode = isPaused ? DateTimeModes.range : values.actualDateTimeMode;

  if (onlyRealtimeLocal) {
    mode = DateTimeModes.realtime;
  }

  const ToWrapper = mode === DateTimeModes.now ? Tooltip : Fragment;
  const toProps =
    mode === DateTimeModes.now
      ? { title: 'Updated every 60 seconds', arrow: false }
      : {};

  switch (mode) {
    case DateTimeModes.now:
      fromLabel = 'From';
      toLabel = 'To';
      fromDate = dayjs(from).format(format);
      toDate = 'REAL-TIME*';
      break;
    case DateTimeModes.realtime:
      toDate = 'REAL-TIME';
      break;
    default:
      fromLabel = 'From';
      toLabel = 'To';
      fromDate = dayjs(from).format(format);
      toDate = dayjs(to).format(format);
      break;
  }

  return (
    <Dropdown
      {...tail}
      caption={
        <Fragment>
          {!!fromDate && (
            <ItemContainer>
              {!!fromLabel && <ItemLabel>{fromLabel}</ItemLabel>}
              <ItemValue>{fromDate}</ItemValue>
            </ItemContainer>
          )}
          {!!toDate && (
            <ToWrapper {...toProps}>
              <ItemContainer>
                {!!toLabel && <ItemLabel>{toLabel}</ItemLabel>}
                <ItemValue>{toDate}</ItemValue>
              </ItemContainer>
            </ToWrapper>
          )}
        </Fragment>
      }
    >
      {children}
    </Dropdown>
  );
};

DateTimeDropdown.propTypes = {
  values: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
    period: PropTypes.shape(),
    actualDateTimeMode: PropTypes.oneOf(Object.values(DateTimeModes)),
  }).isRequired,
  children: PropTypes.children.isRequired,
};

export {
  DateTimeMenuContainer,
  DateTimeMenuRow,
  DateTimeMenuLabel,
  DateTimeMenuDoneButton,
};

export default DateTimeDropdown;
