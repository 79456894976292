import styled from 'styled-components';

import { Item } from '+components/Menu';

import FontMixin from './FontMixin';

export default styled(Item)`
  ${FontMixin};

  display: block;
  padding: 2px 6px;
  padding-left: ${({ $isIconShown }) => ($isIconShown ? '26px' : null)};
  min-width: 100%;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  * {
    pointer-events: none;
  }

  span {
    display: unset;
    align-items: unset;
    text-decoration: none;
    width: unset;
    padding: unset;
  }
`;
