import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import { Field } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import useAllowlists from '+hooks/useAllowlists';

const AddIpToAllowlistForm = (props) => {
  const { initialValues, isOpen, onSubmit, onToggle } = props;

  const { allowlists } = useAllowlists();

  const allowlistOptions = useMemo(
    () =>
      Object.values(allowlists || {}).map(({ id, name, description }) => ({
        value: id,
        label: name,
        description,
      })),
    [allowlists],
  );

  const doSubmit = useCallback(
    (values) => {
      const allowlistItem = allowlists[values.id];
      onSubmit({
        ...values,
        name: allowlistItem.name,
      });
    },
    [allowlists, onSubmit],
  );

  return (
    <FormModal
      mode="add"
      item={`IP address ${initialValues.ip} to allow list`}
      confirmButtonText="Add"
      initialValues={initialValues}
      onSubmit={doSubmit}
      onToggle={onToggle}
      focusOnFields={false}
      isOpen={isOpen}
      labelOnTop
    >
      <Field
        name="id"
        label="Allow List"
        options={allowlistOptions}
        component={SelectField}
        parse={normalizeSelectValue}
        validate={[validateRequired]}
        required
      />
    </FormModal>
  );
};

AddIpToAllowlistForm.propTypes = {
  initialValues: PropTypes.shape({
    ip: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default AddIpToAllowlistForm;
