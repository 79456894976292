import { css } from 'styled-components';

export const WordBreakMixin = css`
  white-space: pre-wrap !important;
  word-spacing: normal !important;
  word-break: break-all !important;
  overflow-wrap: normal !important;

  line-height: 1.6em !important;

  -webkit-font-smoothing: antialiased;
`;
