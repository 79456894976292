import PropTypes from '+prop-types';
import { Fragment, useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

import styled from 'styled-components';

import ScrollBarOrigin from '+components/ScrollBar/smooth';

import PresetItem, { StyledItem } from './PresetItem';

const ScrollBar = styled(ScrollBarOrigin)`
  padding-top: 0;
  padding-bottom: 5px;
  max-height: 240px;
`;

const ShadowContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 10px;
`;

const TitleItem = styled(StyledItem).attrs({
  disabled: true,
})`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08rem;
`;

const Presets = (props) => {
  const {
    userPresets,
    companyPresets,
    systemPresets,
    recentQueries,
    filter,
    onChooseItem,
    isDefault,
    isAdmin,
  } = props;

  const [scroll, setScroll] = useState();

  useDebounce(
    () => {
      if (scroll) {
        scroll.scrollTop = 0;
      }
    },
    300,
    [filter],
  );

  const emitChooseItem = useCallback(
    (item) => (event) => {
      if (typeof onChooseItem === 'function') {
        onChooseItem(item, event);
      }
    },
    [onChooseItem],
  );

  return (
    <ShadowContainer>
      <ScrollBar ref={setScroll}>
        {!!recentQueries?.length && (
          <Fragment>
            <TitleItem>Recent Queries</TitleItem>
            {recentQueries.map((query) => (
              <PresetItem
                key={`${query.nql}${query.mode}`}
                onClick={emitChooseItem(query)}
                item={query}
                isRecent
              />
            ))}
          </Fragment>
        )}
        {!!userPresets.length && (
          <Fragment>
            <TitleItem>My Presets</TitleItem>
            {userPresets.map((item) => (
              <PresetItem
                key={item.id}
                onClick={emitChooseItem(item)}
                item={item}
                isEditable
              />
            ))}
          </Fragment>
        )}

        {!!companyPresets.length && (
          <Fragment>
            <TitleItem>Company Presets</TitleItem>
            {companyPresets.map((item) => (
              <PresetItem
                key={item.id}
                onClick={emitChooseItem(item)}
                item={item}
                isEditable={isAdmin}
              />
            ))}
          </Fragment>
        )}
        {!!systemPresets.length && (
          <Fragment>
            <TitleItem>System Presets</TitleItem>
            {systemPresets.map((item) => (
              <PresetItem
                key={item.id || item.title || item.preset}
                onClick={emitChooseItem(item)}
                item={item}
                isEditable={isDefault && !!item.id}
              />
            ))}
          </Fragment>
        )}
      </ScrollBar>
    </ShadowContainer>
  );
};

Presets.propTypes = {
  userPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  systemPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  recentQueries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filter: PropTypes.string,
  onChooseItem: PropTypes.func,
  isDefault: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

Presets.defaultProps = {
  filter: null,
  onChooseItem: null,
  isDefault: false,
  isAdmin: false,
};

export default Presets;
