import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';

const seriesId = 'severity-algorithm-category';

export const useAlertsTableData = (filters, userFilters, algorithms) => {
  const aggRequest = useMemo(
    () => ({
      seriesId,
      params: {
        start: userFilters.start,
        end: userFilters.end,
        format: 'keymap',
        series: [
          {
            metric: 'alerts',
            field: [
              'traffic_type',
              'severity',
              'algorithm',
              !!filters.customers?.length && 'customer',
            ].filter(Boolean),
            name: 'events-summary-table',
            size: 1000,
            ...StatsRequest.makeSearch({
              search: filters.nql,
              intersect: filters.intersect,
            }),
          },
        ],
        customers: filters.customers,
      },
    }),
    [
      userFilters.start,
      userFilters.end,
      filters.nql,
      filters.intersect,
      filters.customers,
    ],
  );

  const { series, isFetching } = useStatsRequest({
    context: ContextTypes.alerts,
    requestType: StatsRequest.Types.agg,
    request: aggRequest,
    clearIfRequestChanged: false,
  });

  useLoadingIndicator(isFetching);

  const algorithmsValues = useMemo(
    () => Object.values(algorithms || {}),
    [algorithms],
  );

  return useMemo(() => {
    const dict = (series?.[0]?.buckets || []).reduce((acc, e) => {
      const key = e.algorithm;
      let item = acc[key];

      if (!item) {
        item = {
          ...e,
          count: 0,
          categories: new Set(),
          severity: new Set(),
          customer: new Set(),
        };
        acc[key] = item;
      }

      const itemAlgorithm = algorithmsValues.find(
        (al) => al.name === e.algorithm,
      );
      item.description = itemAlgorithm?.description;
      item.categories = itemAlgorithm?.categories;
      item.severity.add(e.severity);
      item.customer.add(e.customer);
      item.count += e.count;

      return acc;
    }, {});

    return Object.values(dict).map((item) => {
      item.severity = Array.from(item.severity).filter(Boolean);
      item.customer = Array.from(item.customer).filter(Boolean);
      return item;
    });
  }, [series, algorithmsValues]);
};

export default useAlertsTableData;
