import debounce from 'lodash.debounce';

import { actions } from '@/redux/api/nql-complete';

import makeArr from '+utils/makeArr';

export const debouncedFetchSuggestions = (namespaceId) =>
  debounce((dispatch, text, caretPos, fieldType) => {
    dispatch(actions.cancel(namespaceId));
    dispatch(
      actions.fetchSuggestions(
        {
          id: namespaceId,
          text,
          caretPos,
          fieldType,
        },
        namespaceId,
      ),
    );
  }, 150);

export const stopFocusLoss = (evt) => evt.preventDefault();

export const defaultDocTemplateConfig = {
  showLabel: true,
  showDescription: true,
  showInfo: true,
  showExample: true,
  showIntersectable: false,
  showAlias: true,
};

export const startsWithLowerCase = (str, search) =>
  str.toLowerCase().startsWith(search.toLowerCase());

export const templates = {
  label: '# {{value}}',
  description: '{{value}}.',
  info: '{{value}}',
  exampleItem: '* @[{{value}}]',
  example: `
&nbsp;
&nbsp;
## Example{{s}}
{{value}}
&nbsp;
&nbsp;
`,
  intersectable: `
## Intersectable: {{value}}
`,
  alias: `
## Alias: {{value}}
`,
  doc: `
{{label}}
{{description}}
{{info}}
{{example}}
{{alias}}
{{intersectable}}
`,
};

const applyLabelTemplate = (value) =>
  value ? templates.label.replace(/{{value}}/g, value) : '';

const applyDescriptionTemplate = (value) => {
  if (!value) {
    return '';
  }
  if (value[value.length - 1] === '.') {
    value = value.slice(0, -1);
  }
  return templates.description.replace(/{{value}}/g, value);
};

const applyInfoTemplate = (value) =>
  value ? templates.info.replace(/{{value}}/g, value) : '';

const applyExampleItemTemplate = (value) =>
  value.map((item) => templates.exampleItem.replace(/{{value}}/g, item)).join(`
`);

const applyExampleTemplate = (value) => {
  const fixedValue = makeArr(value).filter(Boolean);
  if (!fixedValue.length) {
    return '';
  }
  const example = templates.example.replace(
    /{{s}}/g,
    fixedValue.length > 1 ? 's' : '',
  );
  return example.replace(/{{value}}/g, applyExampleItemTemplate(fixedValue));
};

const applyIntersectableTemplate = (value) =>
  value != null
    ? templates.intersectable.replace(/{{value}}/g, value ? 'Yes' : 'No')
    : '';

const applyAliasTemplate = (value) =>
  value ? templates.alias.replace(/{{value}}/g, 'Yes') : '';

export const applyDocTemplate = (value, config) => {
  const docTemplateConfig = { ...defaultDocTemplateConfig, ...config };
  let { doc } = value;
  // label
  if (/{{label}}/g.test(doc)) {
    const label = docTemplateConfig.showLabel
      ? applyLabelTemplate(value.label)
      : '';
    doc = doc.replace(/{{label}}/g, label);
  }
  // description
  if (/{{description}}/g.test(doc)) {
    const description = docTemplateConfig.showDescription
      ? applyDescriptionTemplate(value.description)
      : '';
    doc = doc.replace(/{{description}}/g, description);
  }
  // info
  if (/{{info}}/g.test(doc)) {
    const info = docTemplateConfig.showInfo
      ? applyInfoTemplate(value.info)
      : '';
    doc = doc.replace(/{{info}}/g, info);
  }
  // example
  if (/{{example}}/g.test(doc)) {
    const example = docTemplateConfig.showExample
      ? applyExampleTemplate(value.example)
      : '';
    doc = doc.replace(/{{example}}/g, example);
  }
  // intersectable
  if (/{{intersectable}}/g.test(doc)) {
    const intersectable = docTemplateConfig.showIntersectable
      ? applyIntersectableTemplate(value.intersectable)
      : '';
    doc = doc.replace(/{{intersectable}}/g, intersectable);
  }
  // alias
  if (/{{alias}}/g.test(doc)) {
    const alias = docTemplateConfig.showAlias
      ? applyAliasTemplate(value.alias)
      : '';
    doc = doc.replace(/{{alias}}/g, alias);
  }
  return doc.trim();
};

export const isVisible = (scrollbar, elem) => {
  const { bounding } = scrollbar;
  const targetBounding = elem.getBoundingClientRect();

  const h = targetBounding.height * 0.4;
  const w = targetBounding.width * 0.4;

  // check overlapping
  const top = Math.max(bounding.top + h, targetBounding.top);
  const left = Math.max(bounding.left + w, targetBounding.left);
  const right = Math.min(bounding.right - w, targetBounding.right);
  const bottom = Math.min(bounding.bottom - h, targetBounding.bottom);

  return top < bottom && left < right;
};
