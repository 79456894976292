/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useThrottle } from 'react-use';

import { group } from 'd3-array';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';
import StatsRequest from '@/models/StatsRequest';

import { selectors as customerSelectors } from '@/redux/api/customer';

import SeverityLabel from '+components/Labels/SeverityLabel';
import { Col } from '+components/Layout';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';
import { makeId } from '+utils';
import dayjs, { DateFormat } from '+utils/dayjs';

import NoData from '../../../NoData';
import DateContainer from './components/DateContainer';
import DateTitle from './components/DateTitle';
import DateValues from './components/DateValues';
import ItemContainer from './components/ItemContainer';
import ItemContext from './components/ItemContext';
import ItemTitle from './components/ItemTitle';
import TimeContainer from './components/TimeContainer';
import TimeTitle from './components/TimeTitle';

const name = 'EventsSidebar_data';
const throttleTime = 1e3;

const _includeFields = [
  'id',
  'timestamp',
  'start',
  'end',
  'alerttype',
  'severity',
  'description',
  'summary',
];

const Events = (props) => {
  const { padding, activeData } = props;

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  // makeId - make fake namespace in case we have data for `name_`
  const [namespace] = useState(`${name}_${makeId()}`);
  const stopRequest = !activeData?.eventNql;

  const includeFields = useMemo(() => {
    const isSubAccountRequest =
      activeData?.customer && activeData?.customer !== customer?.shortname;
    return [..._includeFields, ...(isSubAccountRequest ? ['customer'] : [])];
  }, [activeData?.customer, customer?.shortname]);

  const additionalSocketOptions = useMemo(() => {
    const isSubAccountRequest =
      activeData?.customer && activeData?.customer !== customer?.shortname;
    return {
      size: 100,
      ...StatsRequest.makeSearch({
        search: activeData?.eventNql,
      }),
      ...(isSubAccountRequest && { customers: [activeData?.customer] }),
    };
  }, [activeData?.eventNql, activeData?.customer, customer?.shortname]);

  const { isFetching, records } = useRealtimeOrRequest({
    name: namespace,
    context: ContextTypes.alerts,
    includeFields,
    additionalSocketOptions,
    stopRequest,
  });

  const data = useThrottle(
    useMemo(
      () =>
        Array.from(
          group(
            records?.toArray() || [],
            (d) => {
              const ts = (d.end ? +d.end : d.timestamp) * 1000;
              return dayjs(ts).format(DateFormat.day);
            },
            (d) => {
              const ts = (d.end ? +d.end : d.timestamp) * 1000;
              return dayjs(ts).format(DateFormat.minuteWithoutDate);
            },
          ),
          ([key, values]) => {
            return {
              key,
              values: Array.from(values, ([k, v]) => ({ key: k, values: v }))
                .sort((a, b) => a.key.localeCompare(b.key))
                .reverse(),
            };
          },
        )
          .sort((a, b) => a.key.localeCompare(b.key))
          .reverse(),
      [records],
    ),
    throttleTime,
  );

  const noDataText = useThrottle(
    isFetching || (!stopRequest && records === undefined)
      ? 'Loading...'
      : 'No events to display',
    throttleTime * 2,
  );

  if (!data?.length) {
    return <NoData padding={padding}>{noDataText}</NoData>;
  }

  return (
    <Col paddingLeft={padding} paddingRight={padding}>
      {data.map(({ key: date, values }) => (
        <DateContainer key={date}>
          <DateTitle>
            {date.split('\n').map((lex) => (
              <div key={lex}>{lex}</div>
            ))}
          </DateTitle>
          <DateValues>
            {values.map(({ key: time, values: val }) =>
              val.map((event, idx) => {
                return (
                  <Link
                    to={`${RoutePaths.searchEvents}/${event.id}${
                      event.customer ? `?customer=${event.customer}` : ''
                    }`}
                    key={`${event.id}-${idx}`}
                  >
                    <TimeContainer>
                      <TimeTitle>
                        {time}
                        <SeverityLabel severity={event.severity} />
                      </TimeTitle>
                      <ItemContainer>
                        <ItemTitle
                          start={event.start}
                          end={event.end}
                          alerttype={event.alerttype}
                        />
                        <ItemContext event={event} />
                      </ItemContainer>
                    </TimeContainer>
                  </Link>
                );
              }),
            )}
          </DateValues>
        </DateContainer>
      ))}
    </Col>
  );
};

Events.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    eventNql: PropTypes.string,
    customer: PropTypes.string,
  }),
};

Events.defaultProps = {
  activeData: null,
};

export default Events;
