import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import isEqual from 'lodash.isequal';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import { ThemeTypes } from '@/models/ThemeTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useSynchronizedCharts from '+hooks/useSynchronizedCharts';
import useUIProperty from '+hooks/useUIProperty';
import dayjs, { DateFormat } from '+utils/dayjs';

import DashboardForm from '../DashboardForm';
import DashboardModeTypes from '../shared/DashboardModeTypes';
import errorDetails from '../shared/errorDetails';
import { getWidgetTitle } from '../shared/utils';
import Widget from '../Widget';
import AddWidget from './components/AddWidget';
import Container from './components/Container';
import ControlPanel from './components/ControlPanel';
import GridLayout from './components/GridLayout';
import NoData from './components/NoData';
import ShareForm from './components/ShareForm';
import WidgetCloneForm from './components/WidgetCloneForm';
import WidgetContainer from './components/WidgetContainer';

const defaultCols = 12;
const defaultRowHeight = 30;
const excludeMetrics = ['card', 'counts'];
const exportContainerId = 'dashboard-export-container';

const Dashboard = (props) => {
  const {
    id: idProp,
    className,
    cols,
    rowHeight,
    containerPadding,
    preventCollision,
    isDraggable,
    isResizable,
    mode: modeProp,
    hideNav: hideNavProp,
    refresher,
    additionalActionItems,
    setAdditionalActionItems,
  } = props;

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const id = idProp || params.id;
  const dashboard = useSelector(dashboardsSelectors.getDashboard(id));
  const isFetching = useSelector(dashboardsSelectors.isFetching);
  const layoutError = useSelector(dashboardsSelectors.getLayoutError);

  const [hideNav] = useUIProperty('hideNav');
  const [, setLocalTheme] = useUIProperty('theme');
  const [isLayoutExportMode, setIsLayoutExportMode] = useUIProperty(
    'isLayoutExportMode',
    false,
  );
  const [localExportContainerId, setLocalExportContainerId] = useState(null);

  const mode = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const modeParam = search.get('mode');
    if (modeParam === DashboardModeTypes.export) {
      return modeParam;
    }
    return modeProp || modeParam;
  }, [modeProp, location.search]);

  const dashboardContainerRef = useRef(null);

  const [compactType, setCompactType] = useState('vertical'); // part of workaround (see useEffect on the bottom)
  const [localLayout, setLocalLayout] = useState([]);

  const [showDashboardSettingsModal, setShowDashboardSettingsModal] =
    useState(false);
  const [showDashboardShareModal, setShowDashboardShareModal] = useState(false);
  const [showDashboardDeleteModal, setShowDashboardDeleteModal] =
    useState(false);

  const [widgetToClone, setWidgetToClone] = useState(null);
  const [widgetToDelete, setWidgetToDelete] = useState(null);

  const [syncMouseHover, setSyncMouseHover] = useUIProperty(
    `sync-mouse-hover-dashboard-${dashboard?.id}`,
    true,
  );

  useSynchronizedCharts(dashboardContainerRef, !syncMouseHover);

  useLoadingIndicator(isFetching);

  const isDefaultCustomer = customer?.shortname === 'default';
  const isEditable = !dashboard?.system || isDefaultCustomer;
  const readOnly =
    mode !== DashboardModeTypes.edit || !isEditable || !permissions?.update;

  // TODO: Should widgets just be a hash in arangodb by default?
  const widgets = useMemo(
    () =>
      (dashboard?.widgets || []).reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
      ),
    [dashboard?.widgets],
  );

  const onDashboardEditToggle = useCallback(() => {
    if (mode === DashboardModeTypes.edit) {
      navigate(`${RoutePaths.dashboards}/${dashboard?.id}`);
    } else {
      navigate(
        `${RoutePaths.dashboards}/${dashboard?.id}?mode=${DashboardModeTypes.edit}`,
      );
    }
  }, [dashboard?.id, mode]);

  const onDashboardSave = useCallback((values) => {
    dispatch(
      dashboardsActions.updateDashboard({
        ...values,
        errorDetails: errorDetails(values.id),
      }),
    );
  }, []);

  const onDashboardSettingsToggle = useCallback(
    () => setShowDashboardSettingsModal((prev) => !prev),
    [],
  );

  const onDashboardPrint = useCallback(async () => {
    let loadingIndicatorEl = document.getElementById(
      'dashboardExportLoadingIndicator',
    );
    if (!loadingIndicatorEl) {
      loadingIndicatorEl = document.createElement('div');
      loadingIndicatorEl.id = 'dashboardExportLoadingIndicator';
      loadingIndicatorEl.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999999999;
          color: #fff;
        `;
      loadingIndicatorEl.innerText = 'Preparing dashboard...';
      document.body.appendChild(loadingIndicatorEl);
    }

    const search = new URLSearchParams(location.search);
    const prevMode = search.get('mode');
    search.set('mode', DashboardModeTypes.export);
    navigate({ search: search.toString() });

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        const exportContainer = document.getElementById(exportContainerId);
        if (exportContainer) {
          clearInterval(interval);
          resolve();
        }
      }, 300);
      // set timeout to prevent infinity loop
      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 30000);
    });

    document.body.removeChild(loadingIndicatorEl);

    const prevOnAfterPrint = window.onafterprint;
    window.onafterprint = function () {
      if (prevMode) {
        search.set('mode', prevMode);
      } else {
        search.delete('mode');
      }
      const searchParams = search.toString();
      navigate(
        `${RoutePaths.dashboards}/${dashboard?.id}${searchParams ? `?${searchParams}` : ''}`,
      );
      window.onafterprint = prevOnAfterPrint;
    };

    window.print();
  }, [location.search, dashboard?.id]);

  const onDashboardShareToggle = useCallback(() => {
    setShowDashboardShareModal((prevValue) => !prevValue);
  }, []);

  const onDashboardSchedule = useCallback(() => {
    navigate(`${RoutePaths.dashboards}/${dashboard?.id}/schedule`);
  }, [dashboard?.id]);

  const onDashboardDeleteToggle = useCallback(() => {
    setShowDashboardDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDashboardDeleteConfirm = useCallback(() => {
    dispatch(dashboardsActions.removeDashboard(dashboard.id));
    setShowDashboardDeleteModal(null);
    navigate(`${RoutePaths.dashboards}`);
  }, [dashboard]);

  const onWidgetDelete = useCallback(
    (widget) => () => setWidgetToDelete(widget),
    [],
  );

  const onWidgetDeleteCancel = useCallback(() => setWidgetToDelete(null), []);

  const onWidgetDeleteConfirm = useCallback(() => {
    if (!widgetToDelete?.id) {
      return;
    }

    dispatch(
      dashboardsActions.removeWidget({
        dashboardId: dashboard.id,
        widgetId: widgetToDelete.id,
        version: dashboard?.version,
        errorDetails: errorDetails(dashboard.id),
      }),
    );

    setWidgetToDelete(null);
  }, [widgetToDelete?.id, dashboard?.id, dashboard?.version]);

  const onWidgetHeightAdjust = useCallback(
    (widget) => () => {
      setLocalLayout((prevValue) => {
        const layoutItem = prevValue.find((item) => item.i === widget.id);
        return prevValue.map((item) => {
          const newItem = { ...item };
          if (newItem.y === layoutItem.y) {
            newItem.h = layoutItem.h;
          }
          return newItem;
        });
      });
    },
    [],
  );

  const onWidgetWidthAdjust = useCallback(
    (widget) => () => {
      setLocalLayout((prevValue) => {
        const layoutItem = prevValue.find((item) => item.i === widget.id);
        return prevValue.map((item) => {
          const newItem = { ...item };
          if (newItem.y === layoutItem.y) {
            newItem.w = layoutItem.w;
          }
          return newItem;
        });
      });
    },
    [],
  );

  const onWidgetClone = useCallback(
    (widget) => () => setWidgetToClone(widget),
    [],
  );

  const onWidgetCloneCancel = useCallback(() => setWidgetToClone(null), []);

  const onWidgetCloneConfirm = useCallback(
    (values) => {
      dispatch(
        dashboardsActions.cloneWidget({
          dashboardFromId: dashboard.id,
          dashboardToId: values.copyTo,
          widgetId: values.id,
          version: dashboard?.version,
          errorDetails: errorDetails(dashboard.id),
        }),
      );

      setWidgetToClone(null);
    },
    [widgetToClone?.id, dashboard?.id, dashboard?.version],
  );

  const onWidgetEdit = useCallback(
    (widget) => () => {
      navigate(`${RoutePaths.dashboards}/${dashboard?.id}/${widget.id}`);
    },
    [dashboard?.id],
  );

  const onWidgetAdd = useCallback(() => {
    navigate(`${RoutePaths.dashboards}/${dashboard?.id}/add`);
  }, [dashboard?.id]);

  const onGoToDashboard = useCallback(
    (value) =>
      navigate(`${RoutePaths.dashboards}${value?.id ? `/${value.id}` : ''}`),
    [navigate],
  );

  const onSyncMouseHoverToggle = useCallback(() => {
    setSyncMouseHover((prevValue) => !prevValue);
  }, []);

  useEffect(() => {
    setLocalLayout(
      (Array.isArray(dashboard?.layout) ? dashboard.layout : []).reduce(
        (acc, { w, h, x, y, i }) =>
          widgets[i] ? [...acc, { w, h, x, y, i }] : acc,
        [],
      ),
    );
  }, [dashboard?.layout, widgets]);

  // react-grid-layout can run onLayoutChange twice, also user can change layout too fast
  // that's why we need to wait a bit before we send new layout to backend
  // Note: do not use onLayoutChange as useCallback (event with debounce) because this variant
  // is not stable on very quick layout changes
  useDebounce(
    () => {
      if (!dashboard?.id) {
        return;
      }

      const originalLayoutMapped = dashboard?.layout.reduce(
        (acc, { w, h, x, y, i }) =>
          widgets[i] ? [...acc, { w, h, x, y, i }] : acc,
        [],
      );

      // We need to check for existing widgets (widgets[i] ? ... : ...) for last widget remove case
      // @see: https://netography.atlassian.net/browse/PORTAL-1358
      const updatedLayoutMapped = localLayout.reduce(
        (acc, { w, h, x, y, i }) =>
          widgets[i] ? [...acc, { w, h, x, y, i }] : acc,
        [],
      );

      if (isEqual(originalLayoutMapped, updatedLayoutMapped)) {
        return;
      }

      dispatch(
        dashboardsActions.updateLayout({
          id: dashboard?.id,
          version: dashboard?.version,
          layout: localLayout,
          errorDetails: errorDetails(dashboard?.id),
        }),
      );
    },
    3000,
    [
      localLayout,
      widgets,
      dashboard?.layout,
      dashboard?.id,
      dashboard?.version,
    ],
  );

  // Workaround to refresh (reset) dashboard layout in case when layout was changed but backend returned an error while saving it
  // (for example user changed layout but dashboard was outdated and user can't update it)
  // We need this workaround because react-grid-layout cashing layout in local state
  // and there is only one way to refresh (reset) this cache - change compactType prop
  useEffect(() => {
    if (!layoutError) {
      // Layout was updated without errors - skip
      return undefined;
    }
    setCompactType('horizontal');
    const timer = setTimeout(() => {
      setCompactType('vertical');
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [layoutError]);

  useEffect(() => {
    if (!dashboard?.id && id) {
      dispatch(dashboardsActions.fetchDashboard({ id }));
    }
  }, [dashboard?.id, id]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    if (dashboard?.id && !dashboard.system) {
      setMasqueradeUrl(`${RoutePaths.dashboards}`);
    }
    return () => {
      setMasqueradeUrl(null);
    };
  }, [dashboard]);

  useEffect(() => {
    const dashboardEl = dashboardContainerRef.current;
    if (!dashboardEl || !dashboard?.id || !customer?.organization) {
      return undefined;
    }

    if (mode !== DashboardModeTypes.export) {
      return undefined;
    }

    (async () => {
      // Note: Order of these func is important
      setLocalTheme(ThemeTypes.light);

      if (dashboard.widgets?.length) {
        window.dispatchEvent(new Event('beforeChartPrint')); // we need this to show hidden chats

        // wait while hidden charts will be created (rendered into containers)
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            const chartContainers = dashboardEl.getElementsByClassName(
              'widget__chart_container',
            );
            const allChartRendered = Array.from(chartContainers).every(
              (el) => !!el.children.length,
            );
            if (allChartRendered) {
              clearInterval(interval);
              resolve();
            }
          });
          // set timeout to prevent infinity loop
          setTimeout(() => {
            clearInterval(interval);
            resolve();
          }, 10000);
        });

        // wait while charts will load data
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            const chartLoadingElements =
              dashboardEl.getElementsByClassName('highcharts-loading');
            const allChartsLoaded = Array.from(chartLoadingElements).every(
              (el) => el.classList.contains('highcharts-loading-hidden'),
            );
            if (allChartsLoaded) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
          // set timeout to prevent infinity loop
          setTimeout(() => {
            clearInterval(interval);
            resolve();
          }, 10000);
        });

        window.dispatchEvent(new Event('beforeChartPrint')); // we need this to show data labels on charts
      }

      setIsLayoutExportMode(true);

      // small delay to wait while all charts will be rendered and theme will be applied
      window.dispatchEvent(new Event('resize'));
      const delay = Math.max((dashboard.widgets?.length ?? 0) * 300, 1200);
      await new Promise((resolve) => {
        setTimeout(resolve, delay);
      });

      setLocalExportContainerId(exportContainerId);
    })();

    return () => {
      setLocalTheme(null);
      if (dashboard.widgets?.length) {
        window.dispatchEvent(new Event('afterChartPrint'));
      }
      setIsLayoutExportMode(false);
      setLocalExportContainerId(null);
    };
  }, [dashboard, customer?.organization, mode]);

  const controlPanelProps = {
    dashboard,
    mode,
    isEditable,
    hideNav: hideNavProp,
    syncMouseHover,
    showSyncMouseHover: true,
    additionalActionItems,
    onWidgetAdd,
    onDashboardEditToggle,
    onDashboardSettings: onDashboardSettingsToggle,
    onGoToDashboard,
    onDashboardPrint,
    onDashboardShare: onDashboardShareToggle,
    onDashboardSchedule,
    onSyncMouseHoverToggle,
  };

  const controlPanelEl = useMemo(
    () =>
      controlPanelProps.mode === DashboardModeTypes.export ? null : (
        <ControlPanel {...controlPanelProps} />
      ),
    Object.values(controlPanelProps),
  );

  return (
    <Container
      id={localExportContainerId}
      className={className}
      $exportMode={isLayoutExportMode}
    >
      {mode !== DashboardModeTypes.page && (
        <Breadcrumb title={dashboard?.title || 'Dashboard'} />
      )}

      {mode === DashboardModeTypes.export && (
        <Fragment>
          <div className="report-title">{dashboard?.title}</div>
          <div className="report-customer">{customer?.organization}</div>
          <div className="report-date">{dayjs().format(DateFormat.minute)}</div>
        </Fragment>
      )}

      {!hideNav && controlPanelEl}

      {!dashboard && !isFetching && (
        <NoData>
          {!isFetching && (
            <Fragment>
              <div>Dashboard not found.</div>
              {!hideNav && (
                <Link to={`${RoutePaths.dashboards}`}>
                  Go to Manage Dashboards page
                </Link>
              )}
            </Fragment>
          )}
        </NoData>
      )}

      {!!dashboard && (
        <Fragment>
          <GlobalFiltersSetting
            metric
            nql
            context={ContextTypes[dashboard.context] || ContextTypes.flow}
            dateTimeMode={DateTimeModes.now}
            excludeMetrics={excludeMetrics}
            customers
          />
          <div
            ref={dashboardContainerRef}
            className={
              mode === DashboardModeTypes.export ? 'report-body' : null
            }
          >
            {localLayout?.length ? (
              <GridLayout
                layout={localLayout}
                cols={cols}
                rowHeight={rowHeight}
                containerPadding={containerPadding}
                preventCollision={preventCollision}
                compactType={compactType}
                isDraggable={!readOnly && isDraggable}
                isResizable={!readOnly && isResizable}
                onLayoutChange={setLocalLayout}
              >
                {localLayout.map((item) => (
                  <WidgetContainer key={item.i} id={item.i}>
                    <Widget
                      dashboard={dashboard}
                      widget={widgets[item.i]}
                      refresher={refresher}
                      readOnly={readOnly}
                      onDelete={onWidgetDelete(widgets[item.i])}
                      onClone={onWidgetClone(widgets[item.i])}
                      onEdit={onWidgetEdit(widgets[item.i])}
                      onHeightAdjust={onWidgetHeightAdjust(widgets[item.i])}
                      onWidthAdjust={onWidgetWidthAdjust(widgets[item.i])}
                      setAdditionalActionItems={setAdditionalActionItems}
                    />
                  </WidgetContainer>
                ))}
              </GridLayout>
            ) : (
              <NoData>
                <div>No widgets to display.</div>
                {!readOnly && !hideNav && (
                  <AddWidget onClick={onWidgetAdd}>Add widget</AddWidget>
                )}
              </NoData>
            )}
          </div>
        </Fragment>
      )}

      {mode === DashboardModeTypes.export && (
        <div className="report-logo">
          <img src="/images/logos/logo_light.png" alt="" />
        </div>
      )}

      {showDashboardSettingsModal && (
        <DashboardForm
          dashboard={dashboard}
          toggleModal={onDashboardSettingsToggle}
          onConfirm={onDashboardSettingsToggle}
          onSave={onDashboardSave}
          deleteButtonText="Delete Dashboard"
          onDelete={onDashboardDeleteToggle}
          deleteButtonHidden={!dashboard.id}
          deleteButtonDisabled={!permissions?.delete}
          editMode
          isOpen
        />
      )}

      {showDashboardShareModal && (
        <ShareForm
          dashboard={dashboard}
          onToggle={onDashboardShareToggle}
          isOpen
        />
      )}

      {showDashboardDeleteModal && (
        <ConfirmModal
          item={dashboard.title}
          onToggle={onDashboardDeleteToggle}
          onConfirm={onDashboardDeleteConfirm}
          isOpen
        />
      )}

      {!!widgetToClone && (
        <WidgetCloneForm
          currentWidgetId={widgetToClone.id}
          currentDashboardId={dashboard.id}
          item={getWidgetTitle(widgetToClone)}
          isDefaultCustomer={isDefaultCustomer}
          onConfirm={onWidgetCloneConfirm}
          onToggle={onWidgetCloneCancel}
          isOpen
        />
      )}

      {!!widgetToDelete && (
        <ConfirmModal
          item={getWidgetTitle(widgetToDelete)}
          onToggle={onWidgetDeleteCancel}
          onConfirm={onWidgetDeleteConfirm}
          isOpen
        />
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  cols: PropTypes.number,
  rowHeight: PropTypes.number,
  containerPadding: PropTypes.arrayOf(PropTypes.number),
  preventCollision: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isResizable: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(DashboardModeTypes)),
  hideNav: PropTypes.bool,
  refresher: PropTypes.number,
  additionalActionItems: PropTypes.children,
  setAdditionalActionItems: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
};

Dashboard.defaultProps = {
  id: undefined,
  className: '',
  mode: undefined,
  hideNav: false,
  refresher: undefined,
  cols: defaultCols,
  rowHeight: defaultRowHeight,
  containerPadding: [0, 0],
  preventCollision: false,
  isDraggable: true,
  isResizable: true,
  additionalActionItems: null,
  setAdditionalActionItems: null,
};

export { GridLayout, WidgetContainer, defaultCols, defaultRowHeight };

export default Dashboard;
