import styled from 'styled-components';

import IconButtonOrigin from '+components/IconButton';

const ApplyButton = styled(IconButtonOrigin)`
  color: ${({ theme }) => theme.iconButtonText};
  background-color: ${({ theme }) => theme.iconButtonBackground};

  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.iconButtonText};
    background-color: ${({ theme }) => theme.iconButtonBackground};
  }

  &.MuiIconButton-sizeSmall {
    width: 28px;
    height: 28px;
    padding: 4px;
  }
`;

export default ApplyButton;
