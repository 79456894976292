import styled from 'styled-components';

import FontMixin from './FontMixin';
import { WordBreakMixin } from './WordBreakMixin';

export default styled.textarea.attrs({
  spellCheck: false,
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  'data-gramm': false,
  'data-gramm_editor': false,
  'data-enable-grammarly': false,
})`
  ${FontMixin};

  ${WordBreakMixin};

  --buttons-width: ${({ $hasPresets }) => ($hasPresets ? 57 : 0)}px;
  height: 100%;
  min-height: 30px !important;
  border: unset !important;
  border-radius: unset !important;
  padding: 6px 7px 0 7px !important;
  resize: none;
  position: absolute;
  top: 0;
  left: var(--buttons-width);
  bottom: 0;
  width: calc(100% - var(--buttons-width)) !important;

  background: transparent !important;
  color: inherit !important;

  overflow: hidden;

  -webkit-font-smoothing: antialiased;

  &:not(:empty) {
    -webkit-text-fill-color: transparent;
  }

  &::selection {
    background: rgba(149, 215, 228, 0.5);
  }

  &[readonly] {
    pointer-events: none;
  }

  &[disabled] {
    opacity: 1 !important;
    cursor: not-allowed;
  }
`;
