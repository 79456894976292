/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import TrafficLightOutlineIcon from 'mdi-react/TrafficLightOutlineIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';

import {
  actions as blocksActions,
  selectors as blocksSelectors,
} from '@/redux/api/blocks';
import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import {
  IntegrationGradientIcon,
  IntegrationIcon,
  TdmGradientIcon,
  TdmIcon,
  TrafficLightGradientIcon,
} from '@/shared/img/icon';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import Collapsible from '+components/Collapsible';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import Table from '+components/Table';
import {
  CellAlgorithmName,
  CellPluginName,
  CellResponsePolicyName,
  UniversalCell,
} from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import Tooltip from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlFieldName from '+utils/getNqlFieldName';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import AlgorithmDetails from './components/AlgorithmDetails';
import { getColumns } from './components/columns';

const BlockDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const [, , pageTabMethods] = usePageTabs();

  const [filters] = useGlobalFilters();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const block = useSelector(blocksSelectors.getRecord(id)) || {};
  const isFetching = useSelector(blocksSelectors.isFetching);

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isFetching);

  const isSubAccountRecord =
    block?.customer && block?.customer !== customer?.shortname;

  const columns = useMemo(
    () => getColumns({ labelContext: filters.labelContext }),
    [filters.labelContext],
  );

  const row = useMemo(() => ({ original: block }), [block]);

  const ipIntellData = useMemo(() => {
    const data = [];
    if (block.dstip) {
      data.push({
        dstip: true,
        ip: block.dstip,
        ipname:
          !filters.labelContext.show || filters.labelContext.ip !== 'name'
            ? []
            : block.dstipname,
        rdns: block.dstrdns,
        bogon: block.bogondst,
        geo: block.dstgeo,
        as: block.dstas,
        iprep: block.dstiprep,
        customer: block.customer,
      });
    }
    if (block.srcip) {
      data.push({
        srcip: true,
        ip: block.srcip,
        ipname:
          !filters.labelContext.show || filters.labelContext.ip !== 'name'
            ? []
            : block.srcipname,
        rdns: block.srcrdns,
        bogon: block.bogonsrc,
        geo: block.srcgeo,
        as: block.srcas,
        iprep: block.srciprep,
        customer: block.customer,
      });
    }
    return data;
  }, [block]);

  const { nql } = useMemo(
    () => getFlowDataFromRecord({ record: block, type: ContextTypes.blocks }),
    [block],
  );

  const onSearch = useCallback(() => {
    const url = getSearchUrl({
      context: ContextTypes.flow,
      nql,
      customer: isSubAccountRecord ? block?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [nql, isSubAccountRecord, block]);

  const onPushToGF = useCallback(() => {
    dispatch(
      globalFiltersActions.changeFilter({
        context: ContextTypes.flow,
        [getNqlFieldName(ContextTypes.flow)]: makeArr(nql),
        ...(isSubAccountRecord && { customers: [block?.customer] }),
      }),
    );
  }, [nql, isSubAccountRecord, block]);

  useEffect(() => {
    if (!id) {
      return undefined;
    }
    const namespace = `blockDetails_fetchBlock_${id}`;
    const search = new URLSearchParams(location.search);
    dispatch(
      blocksActions.fetch({ id, customer: search.get('customer') }, namespace),
    );
    return () => {
      dispatch(blocksActions.cancel(namespace));
    };
  }, [id, location.search]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  if (!block?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Block Details" />

        <GlobalFiltersSetting
          context={ContextTypes.blocks}
          dateTimeMode={DateTimeModes.now}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.card}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isFetching ? 'Loading...' : 'Block not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="Block Details" />

      <GlobalFiltersSetting
        context={ContextTypes.blocks}
        dateTimeMode={DateTimeModes.now}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <ActionsContainer>
        <Button
          variant={ButtonVariants.text}
          onClick={() => setRecordModalOpened(true)}
        >
          View Raw Record
        </Button>

        <Button
          variant={ButtonVariants.text}
          startIcon={<MagnifyIcon size={16} />}
          onClick={onSearch}
          disabled={!nql}
        >
          Search Flows
        </Button>

        <Tooltip title="Push Flow NQL to the Global Filters">
          <div>
            <Button
              variant={ButtonVariants.text}
              startIcon={<ArrowTopLeftIcon size={16} />}
              onClick={onPushToGF}
              disabled={!nql}
            >
              Push to Global Filters
            </Button>
          </div>
        </Tooltip>
      </ActionsContainer>

      <Col gap={LayoutSizes.groupGap}>
        <Row
          gap={LayoutSizes.groupGap}
          wrap="nowrap"
          alignItems="center"
          $type={LayoutTypes.card}
        >
          <Row $type={LayoutTypes.field}>
            {isSubAccountRecord && (
              <Col container={false} marginRight="5px">
                <SubAccountTag
                  icon={<AccountMultipleIcon size={16} />}
                  context={block.customer}
                  clickable={false}
                />
              </Col>
            )}
            <Col container={false}>Block ID:</Col>
            <Col $type={LayoutTypes.fieldValue}>{block.id}</Col>
          </Row>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TextBoxIcon size={16} />
              <span>Overview</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Active:</Col>
              <Col $type={LayoutTypes.fieldValue} justifyContent="center">
                {UniversalCell('active')({ row, value: block.active })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Adapter:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('adapter')({ row, value: block.adapter })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Protocol:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('protocol')({ row, value: block.protocol })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Update Count:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('updatecount')({
                  row,
                  value: block.updatecount,
                })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ClockIcon size={16} />
              <span>Time</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Start:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('start')({ row, value: block.start })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>End:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('end')({ row, value: block.end })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Expiration:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('expiration')({ row, value: block.expiration })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Last Update:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('lastupdate')({ row, value: block.lastupdate })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <RoutesIcon size={16} />
              <span>Traffic</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Source:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                filters.labelContext.ip !== 'name' ||
                !block.srcipname?.length
                  ? UniversalCell('srcip')({
                      row,
                      value: block.srcip,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell('srcipname')({
                      row,
                      value: block.srcipname,
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>SRC Port:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                filters.labelContext.port !== 'name' ||
                !block.srcportname?.length
                  ? UniversalCell('srcport')({
                      row,
                      value: block.srcport,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell('srcportname')({
                      row,
                      value: block.srcportname,
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Destination:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                filters.labelContext.ip !== 'name' ||
                !block.dstipname?.length
                  ? UniversalCell('dstip')({
                      row,
                      value: block.dstip,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell('dstipname')({
                      row,
                      value: block.dstipname,
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>DST Port:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                filters.labelContext.port !== 'name' ||
                !block.dstportname?.length
                  ? UniversalCell('dstport')({
                      row,
                      value: block.dstport,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell('dstportname')({
                      row,
                      value: block.dstportname,
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TdmIcon size={16} />
              <span>Detection Model</span>
            </Row>

            {(block.tdm || []).map((item) => (
              <Row key={item.id} $type={LayoutTypes.field}>
                <AlgorithmDetails
                  block={block}
                  tdm={item}
                  icon={TdmGradientIcon}
                  formatter={CellAlgorithmName}
                />
              </Row>
            ))}
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TrafficLightOutlineIcon size={16} />
              <span>Response Policies</span>
            </Row>

            {(block.rules || []).map((item) => (
              <Collapsible
                key={item.name}
                {...item}
                formatter={CellResponsePolicyName}
                icon={TrafficLightGradientIcon}
                original={block}
              />
            ))}
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <IntegrationIcon size={16} />
              <span>Integrations</span>
            </Row>
            {block.plugin && (
              <Collapsible
                name={block.plugin?.name}
                id={block.plugin?.id}
                description={block.plugin?.description}
                formatter={CellPluginName}
                icon={IntegrationGradientIcon}
                original={block}
              />
            )}
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap} $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <InformationIcon size={16} />
            <span>IP Intelligence</span>
          </Row>

          <Row>
            <Col sm={12} item container={false}>
              <Table
                id="Block_Details_Ip_Intell"
                data={ipIntellData}
                columns={columns}
                minRows={1}
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Block Record — ${block.id}`}
          data={block}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default withMenu(BlockDetails);
