import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import GearOutlineIcon from 'mdi-react/GearOutlineIcon';

import RoutePaths from '@/models/RoutePaths';

import TopbarButton from '+components/TopbarButton';

const AccountSettingsButton = () => {
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(`${RoutePaths.settings}`), []);

  return (
    <TopbarButton
      type="button"
      title="Settings"
      onClick={onClick}
      data-tracking="account-settings-button"
    >
      <GearOutlineIcon size={22} />
    </TopbarButton>
  );
};

export default AccountSettingsButton;
