import uniq from 'lodash.uniq';

import { ContextTypes } from '@/models/ContextTypes';
import FieldsSeparator from '@/models/FieldsSeparator';
import { isCardMetric } from '@/models/MetricSettings';
import StatsRequest from '@/models/StatsRequest';
import { WidgetTypes } from '@/models/WidgetTypes';

import { timeBounds } from '+utils';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';
import getStackedBarInterval from '+utils/getStackedBarInterval';
import makeArr from '+utils/makeArr';
import nqlLang from '+utils/nqlLang';

import NqlModeTypes from './NqlModeTypes';

export const DateTimeLevel = {
  global: 'global',
  dashboard: 'dashboard',
  widget: 'widget',
};

export const getIntervalInMs = (interval) => {
  if (interval === 'auto') {
    return interval;
  }

  const duration = interval.slice(0, -1);
  const format = interval.slice(-1);
  switch (format) {
    case 'w':
      return duration * 604800 * 1000;
    case 'd':
      return duration * 86400 * 1000;
    case 'h':
      return duration * 3600 * 1000;
    case 'm':
      return duration * 60 * 1000;
    case 's':
      return duration * 1 * 1000;
    default:
      return duration;
  }
};

export const getRequestFrameInMs = (start, end) => {
  // "start" and "end" could be in milliseconds OR in seconds with minus sign
  // "start" example 1 (ms): 1632811200000
  // "start" example 2 (s): -3600
  // "end" example 1 (ms): 1632814800000
  // "end" example 2 (s): 0

  // if "start" is negative or 0, it is in seconds
  if (start <= 0) {
    start = Date.now() + start * 1000;
  }

  // if "end" is negative or 0, it is in seconds
  if (end <= 0) {
    end = Date.now() + end * 1000;
  }

  return end - start;
};

export const getRequestInterval = (start, end, interval) => {
  let requestInterval = interval === 'auto' ? undefined : interval;

  if (requestInterval) {
    const intervalInMs = getIntervalInMs(interval);
    const requestFrameInMs = getRequestFrameInMs(start, end);
    if (intervalInMs > requestFrameInMs) {
      requestInterval = undefined;
    }
  }

  return requestInterval || undefined;
};

export const makeName = (metric, fields) =>
  [metric, ...(fields || [])].filter(Boolean).join(FieldsSeparator);

export const makeStatsRequest = (props) => {
  const {
    // main params
    namespace,
    format,
    chart,
    start,
    end,
    // series params
    series: seriesProp,
  } = props;

  const series = (seriesProp || []).map((item) => {
    const { metric, fields, size, interval, summary, nql, intersect } =
      item || {};
    const intervalLocal = getRequestInterval(start, end, interval);
    return {
      name: makeName(metric, fields),
      metric,
      ...(fields?.length && {
        field: fields,
        ...(fields?.length > 1 && { fields_separator: FieldsSeparator }),
        ...(!isCardMetric(metric) && { size }),
      }),
      ...(intervalLocal && { interval: intervalLocal }),
      ...(summary && { summary }),
      ...StatsRequest.makeSearch({
        search: nql,
        intersect,
      }),
    };
  });

  return {
    seriesId: namespace,
    params: {
      ...(format && { format }),
      ...(chart && { chart }),
      start,
      end,
      series,
      ...(seriesProp?.[0]?.customers?.length && {
        customers: seriesProp?.[0]?.customers,
      }),
    },
  };
};

const contextNames = {
  [ContextTypes.alerts]: 'alert',
  [ContextTypes.blocks]: 'block',
  [ContextTypes.dns]: 'dns',
  [ContextTypes.flow]: 'flow',
  [ContextTypes.traffic]: 'traffic',
};

export const makeSearchRequest = (props) => {
  const {
    // ws params
    limit,
    // main params
    start,
    end,
    // series params
    series: seriesProp,
  } = props;

  const { context, nql, intersect, additionalSearch } = seriesProp?.[0] || {};

  return {
    limit,
    start,
    end,
    size: 1000,
    feed: contextNames[context] || context,
    return: ['all'],
    type: 'search',
    format: 'object',
    sort: {
      field: context === ContextTypes.blocks ? 'start' : 'timestamp',
      order: 'desc',
    },
    ...StatsRequest.makeSearch({
      search: nql,
      andSearch: additionalSearch,
      intersect,
    }),
    ...(seriesProp?.[0]?.customers?.length && {
      customers: seriesProp?.[0]?.customers,
    }),
  };
};

export const getWidgetMetric = (widgetMetric, filtersMetric) =>
  widgetMetric === 'inherit' ? filtersMetric : widgetMetric;

export const getWidgetTimePeriod = (
  mode,
  global,
  dashboard,
  widget,
  realtime = true,
) => {
  let params;
  switch (mode) {
    case DateTimeLevel.global:
      params = {
        from: global?.from,
        to: global?.to,
        period: global?.period,
      };
      break;
    case DateTimeLevel.dashboard:
      params = {
        from: dashboard?.from,
        to: dashboard?.to,
        period: {
          type: dashboard?.periodType,
          value: +dashboard?.periodValue,
        },
      };
      break;
    default:
      params = {
        from: widget?.from,
        to: widget?.to,
        period: {
          type: widget?.periodType,
          value: +widget?.periodValue,
        },
      };
      break;
  }

  return timeBounds({
    period: params.period,
    from: new Date(params.from),
    to: new Date(params.to || global.to), // Only Global Filters store paused date ("to" date)
    realtime,
  });
};

export const getWidgetPeriodType = (mode, global, dashboard, widget) => {
  switch (mode) {
    case DateTimeLevel.global:
      return global;
    case DateTimeLevel.dashboard:
      return dashboard;
    default:
      return widget;
  }
};

export const getWidgetDateTimeMode = (useGlobal, useDashboard) => {
  if (useDashboard && useGlobal) {
    return DateTimeLevel.global;
  }
  if (useDashboard) {
    return DateTimeLevel.dashboard;
  }
  return DateTimeLevel.widget;
};

export const getWidgetFields = ({
  labelContext,
  context,
  metric,
  fields,
  maxFieldCount,
}) => {
  if (!labelContext?.show || !fields?.length || isCardMetric(metric)) {
    return fields;
  }

  const maxAdditionalFieldCount = maxFieldCount - fields.length;

  if (maxAdditionalFieldCount < 1) {
    return fields;
  }

  let _fields = [...fields];

  switch (context) {
    case ContextTypes.flow: {
      let addedFieldCount = 0;
      _fields = fields.reduce((acc, field) => {
        acc.push(field);

        if (addedFieldCount >= maxAdditionalFieldCount) {
          return acc;
        }

        if (['srcip', 'dstip'].includes(field)) {
          const labelFieldName = `label.ip.${labelContext.ip}.${
            field === 'srcip' ? 'src' : 'dst'
          }`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        if (['srcport', 'dstport'].includes(field)) {
          const labelFieldName = `label.port.${labelContext.port}.${
            field === 'srcport' ? 'src' : 'dst'
          }`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        return acc;
      }, []);

      return _fields;
    }
    case ContextTypes.dns: {
      let addedFieldCount = 0;
      _fields = fields.reduce((acc, field) => {
        acc.push(field);

        if (addedFieldCount >= maxAdditionalFieldCount) {
          return acc;
        }

        if (field === 'srcip') {
          const labelFieldName = `label.ip.${labelContext.ip}.src`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        if (field === 'srcport') {
          const labelFieldName = `label.port.${labelContext.port}.src`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        return acc;
      }, []);

      return _fields;
    }
    case ContextTypes.alerts: {
      let addedFieldCount = 0;
      _fields = fields.reduce((acc, field) => {
        acc.push(field);

        if (addedFieldCount >= maxAdditionalFieldCount) {
          return acc;
        }

        if (labelContext.ip === 'name' && ['ipinfo.ip'].includes(field)) {
          const labelFieldName = 'ipinfo.ipname';
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        if (
          labelContext.port === 'name' &&
          ['srcports', 'dstports'].includes(field)
        ) {
          const labelFieldName = `${
            field === 'srcports' ? 'srcportnames' : 'dstportnames'
          }`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        return acc;
      }, []);

      return _fields;
    }
    case ContextTypes.blocks: {
      let addedFieldCount = 0;
      _fields = fields.reduce((acc, field) => {
        acc.push(field);

        if (addedFieldCount >= maxAdditionalFieldCount) {
          return acc;
        }

        if (labelContext.ip === 'name' && ['srcip', 'dstip'].includes(field)) {
          const labelFieldName = `${
            field === 'srcip' ? 'srcipname' : 'dstipname'
          }`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        if (
          labelContext.port === 'name' &&
          ['srcport', 'dstport'].includes(field)
        ) {
          const labelFieldName = `${
            field === 'srcport' ? 'srcportname' : 'dstportname'
          }`;
          const labelIndex = fields.findIndex((f) => f === labelFieldName);
          if (labelIndex === -1) {
            acc.push(labelFieldName);
            addedFieldCount += 1;
          }
        }

        return acc;
      }, []);

      return _fields;
    }
    default:
      return _fields;
  }
};

export const getDashboardNql = (dashboard, globalNql) => {
  let fullNql;

  const dashboardNql = makeArr(dashboard.nql)
    .filter((item) => !(item.hidden ?? false))
    .map((item) => item.value ?? item);

  switch (dashboard.nqlMode) {
    case NqlModeTypes.override:
      fullNql = dashboardNql;
      break;

    case NqlModeTypes.append: {
      const globalNqlArr = !globalNql?.length ? [''] : globalNql;
      fullNql = globalNqlArr.map((item) => nqlLang.and(item, dashboardNql[0]));
      break;
    }

    case NqlModeTypes.global:
    default:
      fullNql = globalNql;
      break;
  }

  return (fullNql || []).filter(Boolean);
};

export const getWidgetNql = (widgetSeries, dashboard, globalNql) => {
  let nql;

  const widgetNql = makeArr(widgetSeries.nql)
    .filter((item) => !(item.hidden ?? false))
    .map((item) => item.value ?? item);

  switch (widgetSeries.nqlMode) {
    case NqlModeTypes.override:
      nql = widgetNql;
      break;

    case NqlModeTypes.append: {
      const dash = widgetSeries.context === dashboard.context ? dashboard : {};
      const dashboardFullNql = getDashboardNql(dash, globalNql);
      if (!dashboardFullNql.length) {
        dashboardFullNql.push('');
      }
      nql = dashboardFullNql.map((item) => nqlLang.and(item, widgetNql[0]));
      break;
    }

    case NqlModeTypes.inherit:
    default: {
      const dash = widgetSeries.context === dashboard.context ? dashboard : {};
      nql = getDashboardNql(dash, globalNql);
      break;
    }
  }

  return (nql || []).filter(Boolean);
};

export const getDashboardIntersect = (dashboard, globalIntersect) => {
  let intersect;

  const dashboardIntersect = makeArr(dashboard.intersect);
  const fixedGlobalIntersect = makeArr(globalIntersect);

  switch (dashboard.nqlMode) {
    case NqlModeTypes.override:
      intersect = dashboardIntersect;
      break;

    case NqlModeTypes.append:
      intersect = [...fixedGlobalIntersect, ...dashboardIntersect];
      break;

    case NqlModeTypes.global:
    default:
      intersect = fixedGlobalIntersect;
      break;
  }

  return (intersect || []).filter(Boolean);
};

export const getWidgetIntersect = (
  widgetSeries,
  dashboard,
  globalIntersect,
) => {
  let intersect;

  const widgetIntersect = makeArr(widgetSeries.intersect);

  switch (widgetSeries.nqlMode) {
    case NqlModeTypes.override:
      intersect = widgetIntersect;
      break;

    case NqlModeTypes.append: {
      const dash = widgetSeries.context === dashboard.context ? dashboard : {};
      intersect = [
        ...getDashboardIntersect(dash, globalIntersect),
        ...widgetIntersect,
      ];
      break;
    }

    case NqlModeTypes.inherit:
    default: {
      const dash = widgetSeries.context === dashboard.context ? dashboard : {};
      intersect = getDashboardIntersect(dash, globalIntersect);
      break;
    }
  }

  return (intersect || []).filter(Boolean);
};

export const getDashboardCustomers = (dashboard, globalCustomers) => {
  let customers;

  const dashboardCustomers = makeArr(dashboard.customers);
  const fixedGlobalCustomers = makeArr(globalCustomers);

  switch (dashboard.nqlMode) {
    case NqlModeTypes.override:
      customers = dashboardCustomers;
      break;

    case NqlModeTypes.append:
      customers = [...fixedGlobalCustomers, ...dashboardCustomers];
      break;

    case NqlModeTypes.global:
    default:
      customers = fixedGlobalCustomers;
      break;
  }

  return uniq((customers || []).filter(Boolean));
};

export const getWidgetCustomers = (
  widgetSeries,
  dashboard,
  globalCustomers,
) => {
  let customers;

  const widgetCustomers = makeArr(widgetSeries.customers);

  switch (widgetSeries.nqlMode) {
    case NqlModeTypes.override:
      customers = widgetCustomers;
      break;

    case NqlModeTypes.append:
      customers = [
        ...getDashboardCustomers(dashboard, globalCustomers),
        ...widgetCustomers,
      ];
      break;

    case NqlModeTypes.inherit:
    default: {
      const dash = widgetSeries.context === dashboard.context ? dashboard : {};
      customers = getDashboardCustomers(dash, globalCustomers);
      break;
    }
  }

  return uniq((customers || []).filter(Boolean));
};

export const mapWidgetSeriesItem = (props) => {
  const {
    item,
    widget,
    dashboard,
    allFilters,
    maxFieldCount,
    generateSummary,
    additionalSearch,
  } = props;

  const metric = getWidgetMetric(
    item.metric,
    allFilters[getMetricFieldName(item.context)],
  );

  const fields = getWidgetFields({
    labelContext: allFilters.labelContext,
    context: item.context,
    metric,
    fields: makeArr(item.fields),
    maxFieldCount,
  });

  const interval =
    item.display?.type !== WidgetTypes.StackedBar
      ? item.interval
      : getStackedBarInterval(
          widget.useDashboardDateTime
            ? allFilters.period.value
            : widget.dateTime.periodValue,
          widget.useDashboardDateTime
            ? allFilters.period.type
            : widget.dateTime.periodType,
        );

  const nql = getWidgetNql(
    item,
    dashboard,
    allFilters[getNqlFieldName(item.context)],
  );

  const intersect = getWidgetIntersect(
    item,
    dashboard,
    allFilters[getIntersectFieldName(item.context)],
  );

  const customers = getWidgetCustomers(item, dashboard, allFilters.customers);

  return {
    metric,
    context: item.context,
    fields,
    size: item.size,
    interval,
    nql,
    intersect,
    summary: generateSummary,
    additionalSearch,
    customers,
  };
};
