import PropTypes from '+prop-types';

import { ContextTypes } from '@/models/ContextTypes';

import {
  TrafficColumns,
  trafficDefaultColumns,
  TrafficTable,
} from '+components/ContextTables';
import SearchPage from '+components/SearchPage';

const tableSortBy = [
  {
    id: 'timestamp',
    desc: true,
  },
];

const requestSort = {
  field: 'timestamp',
  order: 'desc',
};

const columns = [...trafficDefaultColumns, TrafficColumns.trafficRecord];

const Table = ({ data = [], ...tail }) => (
  <TrafficTable
    {...tail}
    id="Search_TrafficSearch_Table"
    tableData={data}
    sortBy={tableSortBy}
    noDataText={tail.loading ? 'Searching...' : undefined}
    columns={columns}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  data: [],
};

const Search = ({ allowNlp }) => (
  <SearchPage
    nqlContext={ContextTypes.traffic}
    nqlPlaceholder="srcip == 10.0.0.12 and srcport == 53"
    resultRenderer={Table}
    sort={requestSort}
    allowNlp={allowNlp}
  />
);

Search.propTypes = {
  allowNlp: PropTypes.bool,
};

Search.defaultProps = {
  allowNlp: false,
};

export default Search;
