import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';

import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import {
  actions as socketControlActions,
  selectors as socketControlSelectors,
} from '@/redux/ui/socketControl';

import IconButton from '+components/IconButton';
import { usePortalSettingsValue } from '+hooks';

/**
 * Array holding pages that must be realtime by default.
 * these paths override realTimePaused settings until user don't press pause button.
 *
 * @type {string[]}
 */
const realtimePaths = [`${RoutePaths.realTimeFlowMap}`];

const SocketControl = styled((props) => {
  const dispatch = useDispatch();
  const isPaused = useSelector(socketControlSelectors.isPaused);
  const hadUserAction = useRef();

  const location = useLocation();

  const [realTimePaused] = usePortalSettingsValue(
    SettingCategories.system,
    'realTimePaused',
    true,
  );
  const [companyRealTimePaused] = usePortalSettingsValue(
    SettingCategories.ui,
    'companyRealTimePaused',
    realTimePaused,
  );
  const [userRealTimePaused] = usePortalSettingsValue(
    SettingCategories.ui,
    'userRealTimePaused',
    companyRealTimePaused,
  );

  const onPauseToggle = useCallback(
    (event) => {
      hadUserAction.current = true;

      event.stopPropagation();
      if (isPaused) {
        dispatch(socketControlActions.resume());
      } else {
        dispatch(socketControlActions.pause());
      }
    },
    [isPaused],
  );

  useEffect(() => {
    if (hadUserAction.current) {
      return;
    }

    if (realtimePaths.includes(location.pathname)) {
      if (isPaused) {
        dispatch(socketControlActions.resume());
      }

      return;
    }

    if (userRealTimePaused) {
      if (!isPaused) {
        dispatch(socketControlActions.pause());
      }

      return;
    }

    if (isPaused) {
      dispatch(socketControlActions.resume());
    }
  }, [isPaused, location.pathname, userRealTimePaused]);

  return (
    <IconButton
      {...props}
      onClick={onPauseToggle}
      data-tracking="pause-resume-button"
    >
      {isPaused ? <PlayIcon /> : <PauseIcon />}
    </IconButton>
  );
})`
  color: ${({ theme }) => theme.iconButtonInsideComponentsText};
  background-color: ${({ theme }) =>
    theme.iconButtonInsideComponentsBackground};

  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.iconButtonInsideComponentsText};
    background-color: ${({ theme }) =>
      theme.iconButtonInsideComponentsBackground};
  }

  &.MuiIconButton-sizeSmall {
    width: 28px;
    height: 28px;
    padding: 4px;
  }
`;

export default SocketControl;
