import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ContextTypes } from '@/models/ContextTypes';

import { AuditLogTable } from '+components/ContextTables';
import SearchPage from '+components/SearchPage';
import { makeId } from '+utils/general';

import Details from './components/Details';

const tableSortBy = [
  {
    id: 'timestamp',
    desc: true,
  },
];

const requestSort = {
  field: 'timestamp',
  order: 'desc',
};

const Table = ({ data, searchId, ...tail }) => (
  <AuditLogTable
    {...tail}
    id="Search_AuditLogsSearch_Table"
    searchId={searchId}
    data={data}
    filters={null}
    noDataText={tail.loading ? 'Searching...' : undefined}
    sortBy={tableSortBy}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  searchId: PropTypes.string,
};

Table.defaultProps = {
  data: null,
  searchId: null,
};

const AuditLogs = (props) => {
  const { defaultNql, runOnLoad, ...tail } = props;

  const wasRun = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let needToReplace = false;

    const search = new URLSearchParams(location.search);

    if (defaultNql && !search.has('nql')) {
      needToReplace = true;
      search.set('nql', defaultNql);
    }

    if (runOnLoad && !wasRun.current && !search.has('reqid')) {
      needToReplace = true;
      search.set('reqid', makeId());
    }

    if (needToReplace) {
      navigate({ search: search.toString() }, { replace: true });
    }

    wasRun.current = true;
  }, [runOnLoad]);

  return (
    <SearchPage
      {...tail}
      nqlContext={ContextTypes.audit}
      nqlPlaceholder="action == create"
      resultRenderer={Table}
      sort={requestSort}
      hideGFButtons
    />
  );
};

AuditLogs.propTypes = {
  defaultNql: PropTypes.string,
  runOnLoad: PropTypes.bool,
};

AuditLogs.defaultProps = {
  defaultNql: '',
  runOnLoad: false,
};

export { Details as AuditLogDetails };

export default AuditLogs;
