import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import cloneDeep from 'lodash.clonedeep';
import styled from 'styled-components';

import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import FormField from '+components/form/FormField';
import TableOrigin from '+components/Table';
import { MenuColumnButton } from '+components/Table/Columns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

const Table = styled(TableOrigin)`
  width: 100%;
  &.invalid {
    border: 1px solid ${({ theme }) => theme.colorFieldsBorderInvalid};
  }
`;

const TableField = (props) => {
  const {
    className,
    input: { value, onChange, ...input },
    columnTypes,
    columnCollection,
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const onDelete = useCallback(
    (index) => () => {
      const newValue = cloneDeep(value);
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange],
  );

  const cxActionMenu = useCallback(
    (_, original, index) => (
      <MenuColumnButton
        title="Delete"
        disabled={disabled}
        onClick={onDelete(index)}
      >
        <TrashCanOutlineIcon size={16} />
      </MenuColumnButton>
    ),
    [disabled, onDelete],
  );

  const columns = useMemo(() => {
    const getColumns = getColumnsHelper(columnCollection);
    return getColumns(Object.values(columnTypes), { cxActionMenu });
  }, [columnCollection, columnTypes, cxActionMenu]);

  return (
    <FormField
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <Table
        {...tail}
        {...input}
        className={classNames(className, { invalid })}
        data={value || []}
        columns={columns}
        disabled={disabled}
      />
    </FormField>
  );
};

TableField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  columnTypes: PropTypes.shape({}).isRequired,
  columnCollection: PropTypes.shape({}).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

TableField.defaultProps = {
  className: null,
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
};

export { TableField };
export default TableField;
