/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import get from 'lodash.get';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import ExportIcon from 'mdi-react/ExportIcon';
import ImportIcon from 'mdi-react/ImportIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dnsActions,
  selectors as dnsSelectors,
} from '@/redux/api/dns';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import Table from '+components/Table';
import { UniversalCell } from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import Tooltip from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlFieldName from '+utils/getNqlFieldName';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import { getColumns } from './components/columns';

const Details = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const [, , pageTabMethods] = usePageTabs();

  const [filters] = useGlobalFilters();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const record = useSelector(dnsSelectors.getRecord(id)) || {};
  const isFetching = useSelector(dnsSelectors.isFetchingRecord(id));

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isFetching);

  const isSubAccountRecord =
    record?.customer && record?.customer !== customer?.shortname;

  const columns = useMemo(
    () => getColumns({ labelContext: filters.labelContext }),
    [filters.labelContext],
  );

  const row = useMemo(() => ({ original: record }), [record]);

  const answers = useMemo(
    () =>
      (record?.answers || []).map((answer, index) => ({
        ...record,
        id: `${record?.id}_${index}`,
        answers: answer,
      })),
    [record?.answers],
  );

  const { nql } = useMemo(
    () => getFlowDataFromRecord({ record, type: ContextTypes.dns }),
    [record],
  );

  const onSearch = useCallback(() => {
    const url = getSearchUrl({
      context: ContextTypes.dns,
      nql,
      customer: isSubAccountRecord ? record?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [nql, isSubAccountRecord, record]);

  const onPushToGF = useCallback(() => {
    dispatch(
      globalFiltersActions.changeFilter({
        context: ContextTypes.dns,
        [getNqlFieldName(ContextTypes.dns)]: makeArr(nql),
        ...(isSubAccountRecord && { customers: [record?.customer] }),
      }),
    );
  }, [nql, isSubAccountRecord, record]);

  useEffect(() => {
    if (!id) {
      return undefined;
    }
    const namespace = `dnsDetails_fetch_${id}`;
    const search = new URLSearchParams(location.search);
    dispatch(
      dnsActions.fetch({ id, customer: search.get('customer') }, namespace),
    );
    return () => {
      dispatch(dnsActions.cancel(namespace));
    };
  }, [id, location.search]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;

  if (!record?.id) {
    return (
      <Fragment>
        <Breadcrumb title="DNS Details" />

        <GlobalFiltersSetting
          context={ContextTypes.dns}
          dateTimeMode={DateTimeModes.now}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.card}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isFetching ? 'Loading...' : 'DNS not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="DNS Details" />

      <GlobalFiltersSetting
        context={ContextTypes.dns}
        dateTimeMode={DateTimeModes.now}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <ActionsContainer>
        <Button
          variant={ButtonVariants.text}
          onClick={() => setRecordModalOpened(true)}
        >
          View Raw Record
        </Button>

        <Button
          variant={ButtonVariants.text}
          startIcon={<MagnifyIcon size={16} />}
          onClick={onSearch}
          disabled={!nql}
        >
          Search DNS
        </Button>

        <Tooltip title="Push DNS NQL to the Global Filters">
          <div>
            <Button
              variant={ButtonVariants.text}
              startIcon={<ArrowTopLeftIcon size={16} />}
              onClick={onPushToGF}
              disabled={!nql}
            >
              Push to Global Filters
            </Button>
          </div>
        </Tooltip>
      </ActionsContainer>

      <Col gap={LayoutSizes.groupGap}>
        <Row
          gap={LayoutSizes.groupGap}
          wrap="nowrap"
          alignItems="center"
          $type={LayoutTypes.card}
        >
          <Row $type={LayoutTypes.field}>
            {isSubAccountRecord && (
              <Col container={false} marginRight="5px">
                <SubAccountTag
                  icon={<AccountMultipleIcon size={16} />}
                  context={record.customer}
                  clickable={false}
                />
              </Col>
            )}
            <Col container={false}>Type:</Col>
            <Col $type={LayoutTypes.fieldValue}>
              {UniversalCell('type')({ row, value: record.type })}
            </Col>
          </Row>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TextBoxIcon size={16} />
              <span>Overview</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Protocol:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('protocol')({ row, value: record.protocol })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Version:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('version')({
                  row,
                  value: record.version,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Data Src:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('datasrc')({ row, value: record.datasrc })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Provider:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('provider')({ row, value: record.provider })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>RCode:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('rcode')({ row, value: record.rcode })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ClockIcon size={16} />
              <span>Time</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Timestamp:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('timestamp')({ row, value: record.timestamp })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>RTime:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('rtime')({ row, value: record.rtime })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <RoutesIcon size={16} />
              <span>Traffic</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Source:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(record, srcIpLabelFieldName)?.length
                  ? UniversalCell('srcip')({
                      row,
                      value: record.srcip,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(srcIpLabelFieldName)({
                      row,
                      value: get(record, srcIpLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>SRC Port:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(record, srcPortLabelFieldName)?.length
                  ? UniversalCell('srcport')({
                      row,
                      value: record.srcport,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(srcPortLabelFieldName)({
                      row,
                      value: get(record, srcPortLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Site:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('site')({ row, value: record.site })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Region:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('region')({ row, value: record.region })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ImportIcon size={16} />
              <span>Query</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Name:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.name')({
                  row,
                  value: record.query?.name,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Class:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.class')({
                  row,
                  value: record.query?.class,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Type:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.type')({
                  row,
                  value: record.query?.type,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Host:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.host')({
                  row,
                  value: record.query?.host,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Domain:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.domain')({
                  row,
                  value: record.query?.domain,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Depth:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('query.depth')({
                  row,
                  value: record.query?.depth,
                })}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap} $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <ExportIcon size={16} />
            <span>Answers</span>
          </Row>

          <Row>
            <Col sm={12} item container={false}>
              <Table
                id="Dns_Details_Answers"
                data={answers}
                columns={columns}
                minRows={1}
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`DNS Record — ${record.id}`}
          data={record}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default withMenu(Details);
