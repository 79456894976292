import PropTypes from '+prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';

export const Context = createContext();

const Provider = ({ children }) => {
  const [anchor, setAnchor] = useState(null);

  const hide = useCallback(() => setAnchor(null), []);

  const value = useMemo(
    () => ({
      isOpen: !!anchor,
      anchor,
      setAnchor,
      hide,
    }),
    [anchor],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Provider;
