import { Route, Routes } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';

import IpExplorer from '@/pages/IpExplorer';
import RealTimeFlowMap from '@/pages/RealTimeFlowMap';
import RealTimeTraffic from '@/pages/RealTimeTraffic';
import Search from '@/pages/Search';
import { BlockDetails } from '@/pages/Search/Blocks';
import { DnsDetails } from '@/pages/Search/Dns';
import { EventDetails } from '@/pages/Search/Events';
import { FlowDetails } from '@/pages/Search/Flow';
import TrafficMiner from '@/pages/TrafficMiner';
import TrafficTop from '@/pages/TrafficTop';

import { CrumbRoute } from '+components/Breadcrumb';

const InvestigateRoutes = () => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  return (
    <Routes>
      <Route path={`${RoutePaths.search.pageName}/*`}>
        <Route
          path={`${RoutePaths.searchFlow.pageName}/:id`}
          element={<FlowDetails />}
        />
        {isDnsEnabled && (
          <Route
            path={`${RoutePaths.searchDns.pageName}/:id`}
            element={<DnsDetails />}
          />
        )}
        <Route
          path={`${RoutePaths.searchEvents.pageName}/:id`}
          element={<EventDetails />}
        />
        <Route
          path={`${RoutePaths.searchBlocks.pageName}/:id`}
          element={<BlockDetails />}
        />
        <Route path="*" element={<Search />} />
      </Route>

      <Route
        path={
          isDnsEnabled
            ? RoutePaths.realTimeTraffic.pageName
            : RoutePaths.realTimeFlow.pageName
        }
        element={
          <CrumbRoute
            title={isDnsEnabled ? 'Real-Time Traffic' : 'Real-Time Flow'}
            component={RealTimeTraffic}
          />
        }
      />

      <Route
        path={RoutePaths.trafficMiner.pageName}
        element={<CrumbRoute title="Traffic Miner" component={TrafficMiner} />}
      />

      <Route
        path={
          isDnsEnabled
            ? RoutePaths.trafficTop.pageName
            : RoutePaths.networkTop.pageName
        }
        element={
          <CrumbRoute
            title={isDnsEnabled ? 'Traffic Top' : 'Network Top'}
            component={TrafficTop}
          />
        }
      />

      <Route
        path={RoutePaths.ipExplorer.pageName}
        element={<CrumbRoute title="IP Explorer" component={IpExplorer} />}
      />

      <Route
        path={RoutePaths.realTimeFlowMap.pageName}
        element={
          <CrumbRoute title="Real-Time Flow Map" component={RealTimeFlowMap} />
        }
      />
    </Routes>
  );
};

export default InvestigateRoutes;
