import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import { isIP } from 'is-ip';

import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import ChartAreasplineIcon from 'mdi-react/ChartAreasplineIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import GraphOutlineIcon from 'mdi-react/GraphOutlineIcon';
import IpIcon from 'mdi-react/IpIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PlaylistPlusIcon from 'mdi-react/PlaylistPlusIcon';
import ShapeRectanglePlusIcon from 'mdi-react/ShapeRectanglePlusIcon';
import WindowMaximizeIcon from 'mdi-react/WindowMaximizeIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import { getFieldType, getIpFieldType } from '@/models/FieldTypes';
import LabelContextTypes from '@/models/LabelContextTypes';
import LabelTypes from '@/models/LabelTypes';
import PermissionModel from '@/models/Permission';
import { DataTypes } from '@/models/PropertiesTray';
import RoutePaths from '@/models/RoutePaths';

import { actions as allowlistsActions } from '@/redux/api/allowlists';
import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as cvesActions,
  selectors as cvesSelectors,
} from '@/redux/api/cves';
import { actions as ipLabelsActions } from '@/redux/api/labels/ips';
import { actions as portLabelsActions } from '@/redux/api/labels/ports';
import { actions as rulesActions } from '@/redux/api/rules';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import { IpLabelForm } from '@/pages/Labels/IpLabels';
import { PortLabelForm } from '@/pages/Labels/PortLabels';

import ConfirmModal from '+components/ConfirmModal';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import { useFetchContextRecord, useGetContextRecord } from '+hooks';
import usePermissions from '+hooks/usePermissions';
import useThresholdOverrides from '+hooks/useThresholdOverrides';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlByFieldName from '+utils/getNqlByFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';
import { getRecordDetailsUrl } from '+utils/getRecordDetailsUrl';
import { getRecordModalTitle } from '+utils/getRecordModalTitle';
import { getSearchUrl } from '+utils/getSearchUrl';
import { getStreamNql } from '+utils/getStreamNql';
import { getContextType } from '+utils/labels';
import makeArr from '+utils/makeArr';

import { getNqlForLocation } from '../PropertiesTray/components/utils';
import AddIpToAllowlistForm from './components/AddIpToAllowlistForm';
import AddIpToDiscardForm from './components/AddIpToDiscardForm';
import Context from './components/Context';

const Menu = (props) => {
  const { data, children } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [propertiesTray] = useUIProperty('propertiesTray', null);
  const dataLength = propertiesTray?.data?.length || 0;

  const permissionsLabel = usePermissions(
    PermissionModel.Resources.label.value,
  );
  const permissionsWhitelist = usePermissions(
    PermissionModel.Resources.whitelist.value,
  );
  const permissionsThreatModel = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );

  const [, , pageTabMethods] = usePageTabs();
  const isDnsEnabled = useFlag(FeatureFlags.dns);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const [activeLabelData, setActiveLabelData] = useState(null);

  const record = useGetContextRecord(data?.recordType, data?.value?.id);

  useFetchContextRecord(
    data?.recordType,
    {
      id: data?.value?.id,
      customer: data?.customer,
    },
    'propertiesTrayTrigger',
    [data?.recordType, data?.value?.id, data?.customer],
  );

  const menuData = useMemo(
    () => activeLabelData || data,
    [data, activeLabelData],
  );

  /** * IP LABELS ACTIONS ** */

  const [ipLabelToManage, setIpLabelToManage] = useState(null);
  const [ipLabelToDelete, setIpLabelToDelete] = useState(null);

  const onIpLabelAddToggle = useCallback(() => {
    const defaultIpLabel = {
      ip: data?.value,
      context: 'name',
      labels: [],
    };
    setIpLabelToManage((prevValue) => (prevValue ? null : defaultIpLabel));
  }, [data?.value]);

  const onIpLabelAddSubmit = useCallback((values) => {
    if (values.id) {
      dispatch(ipLabelsActions.updateIpLabel(values));
    } else {
      dispatch(ipLabelsActions.createIpLabel(values));
    }
    setIpLabelToManage(null);
  }, []);

  const onIpLabelDeleteToggle = useCallback(() => {
    setIpLabelToDelete((prevValue) => (prevValue ? null : ipLabelToManage));
  }, [ipLabelToManage]);

  const onIpLabelDeleteSubmit = useCallback(() => {
    dispatch(ipLabelsActions.removeIpLabel(ipLabelToDelete));
    setIpLabelToDelete(null);
    setIpLabelToManage(null);
  }, [ipLabelToDelete]);

  /** * PORT LABELS ACTIONS ** */

  const [portLabelToManage, setPortLabelToManage] = useState(null);
  const [portLabelToReset, setPortLabelToReset] = useState(null);
  const [portLabelToDelete, setPortLabelToDelete] = useState(null);

  const onPortLabelVisibilityToggle = useCallback((row, show) => {
    if (show) {
      dispatch(portLabelsActions.showPortLabel(row));
    } else {
      dispatch(portLabelsActions.hidePortLabel(row));
    }
  }, []);

  const onPortLabelAddToggle = useCallback(() => {
    const defaultPortLabel = {
      port: +data?.value,
      protocol: '',
      context: 'name',
      labels: [],
    };
    setPortLabelToManage((prevValue) => (prevValue ? null : defaultPortLabel));
  }, [data?.value]);

  const onPortLabelAddSubmit = useCallback((values) => {
    if (values.id) {
      dispatch(portLabelsActions.updatePortLabel(values));
    } else {
      dispatch(portLabelsActions.createPortLabel(values));
    }
    setPortLabelToManage(null);
  }, []);

  const onPortLabelResetToggle = useCallback(() => {
    setPortLabelToReset((prevValue) => (prevValue ? null : portLabelToManage));
  }, [portLabelToManage]);

  const onPortLabelResetSubmit = useCallback(() => {
    dispatch(portLabelsActions.resetPortLabel(portLabelToReset));
    setPortLabelToReset(null);
    setPortLabelToManage(null);
  }, [portLabelToReset]);

  const onPortLabelDeleteToggle = useCallback(() => {
    setPortLabelToDelete((prevValue) => (prevValue ? null : portLabelToManage));
  }, [portLabelToManage]);

  const onPortLabelDeleteSubmit = useCallback(() => {
    dispatch(portLabelsActions.removePortLabel(portLabelToDelete));
    setPortLabelToDelete(null);
    setPortLabelToManage(null);
  }, [portLabelToDelete]);

  /** * LABELS COMMON ACTIONS ** */

  const onLabelAdd = useCallback(() => {
    if (data?.fieldType === LabelTypes.ip) {
      onIpLabelAddToggle();
    } else {
      onPortLabelAddToggle();
    }
  }, [data?.fieldType, onIpLabelAddToggle, onPortLabelAddToggle]);

  const onLabelEdit = useCallback(
    (type, row) => () => {
      if (type === LabelTypes.ip) {
        setIpLabelToManage(row);
      } else {
        setPortLabelToManage(row);
      }
    },
    [data?.fieldType],
  );

  const onLabelClick = useCallback(
    (type, row, clickedLabel, clickedLabelCustomer) => () => {
      const labelData =
        type === LabelTypes.ip
          ? {
              title: `${row?.context} — ${clickedLabel}`,
              field: `label.${type}.${row?.context}`,
              value: clickedLabel,
            }
          : {
              title: `${row?.protocol} — ${clickedLabel}`,
              field: `label.${type}.${row?.context}`,
              value: clickedLabel,
            };
      const nql = getNqlByFieldName({
        field: labelData.field,
        value: labelData.value,
      });
      const additionalMenuOptions = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: onLabelEdit(type, row),
        },
      ];
      setActiveLabelData({
        ...nql,
        ...labelData,
        dataType: DataTypes.field,
        fieldType: getFieldType(labelData.field),
        customer: clickedLabelCustomer,
        additionalMenuOptions,
      });
    },
    [onLabelEdit],
  );

  /** * ALLOWLISTS ACTIONS ** */
  const [allowlist, setAllowlist] = useState(null);

  const onAddToWhitelistToggle = useCallback(() => {
    const defaultWhitelist = {
      id: '',
      name: '',
      ip: data?.value,
    };
    setAllowlist((prevValue) => (prevValue ? null : defaultWhitelist));
  }, [data?.value]);

  const onAddToWhitelistSubmit = useCallback((values) => {
    dispatch(allowlistsActions.addIpToAllowlist(values));
    setAllowlist(null);
  }, []);

  /** * DISCARD ACTIONS ** */
  const [discard, setDiscard] = useState(null);

  const onAddToDiscardToggle = useCallback(() => {
    setDiscard((prevValue) =>
      prevValue
        ? null
        : {
            ip: data?.value,
          },
    );
  }, [data?.value]);

  const onAddToDiscardSubmit = useCallback((values) => {
    dispatch(rulesActions.updateAlgorithm(values));
    setDiscard(null);
  }, []);

  /** * RECORD ACTIONS ** */
  const [recordModalOpened, setRecordModalOpened] = useState(false);

  const onRecordFullDetails = useCallback(() => {
    if (!record || !data?.recordType) {
      return;
    }

    const isSubAccountRecord =
      record?.customer && record?.customer !== customer?.shortname;
    const url = getRecordDetailsUrl({
      context: data.recordType,
      id: record.id,
      customer: isSubAccountRecord ? record?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [record, data?.recordType, customer?.shortname]);

  const onRecordViewRawRecord = useCallback(() => {
    setRecordModalOpened(true);
  }, []);

  const onRecordSearch = useCallback(
    (context) => {
      if (!record || !data?.recordType) {
        return;
      }

      const isSubAccountRecord =
        record?.customer && record?.customer !== customer?.shortname;
      const { nql, from, to, endIsNow } = getFlowDataFromRecord({
        record,
        type: data?.recordType,
      });
      const url = getSearchUrl({
        context,
        nql,
        customer: isSubAccountRecord ? record?.customer : undefined,
        ...(data.recordType === ContextTypes.alerts && {
          from,
          to: endIsNow ? 'now' : to,
        }),
      });
      pageTabMethods.add(url);
    },
    [record, data?.recordType, customer?.shortname],
  );

  const onRecordPushToGF = useCallback(() => {
    if (!record || !data?.recordType) {
      return;
    }

    const useContextType =
      ((data.value.traffic_type === ContextTypes.dns ||
        data.recordType === ContextTypes.dns) &&
        ContextTypes.dns) ||
      ContextTypes.flow;
    const isSubAccountRecord =
      record?.customer && record?.customer !== customer?.shortname;
    const { nql, from, to, endIsNow } = getFlowDataFromRecord({
      record,
      type: data.recordType,
    });

    let params = {};
    if (data.recordType === ContextTypes.alerts) {
      params = {
        period: {
          type: CustomType,
        },
        from,
        to,
        startIsMin: false,
        endIsNow,
      };
    }

    dispatch(
      globalFiltersActions.changeFilter({
        ...params,
        context: useContextType,
        [getNqlFieldName(useContextType)]: makeArr(nql),
        ...(isSubAccountRecord && { customers: [record?.customer] }),
      }),
    );
  }, [record, data?.recordType, customer?.shortname]);

  /**
   * Overrides actions
   */

  const { getTrackBySearchParams, triggerExists } = useThresholdOverrides(
    record?.tdm?.id,
    record,
  );

  const onRecordOverrides = useCallback(() => {
    const trackBySearchParams = getTrackBySearchParams();

    const path = isDnsEnabled
      ? RoutePaths.modelsDetection
      : RoutePaths.modelsNetwork;

    pageTabMethods.add({
      pathname: `${path}/${record?.tdm?.id}/${RoutePaths.modelOverrides}/update`,
      search: `${trackBySearchParams}${triggerExists() ? '&mode=edit' : ''}`,
    });
  }, [record?.tdm?.id, getTrackBySearchParams, triggerExists, isDnsEnabled]);

  /** * CVE ACTIONS ** */
  const isCveMenuMode = useMemo(() => {
    if (!menuData?.field?.startsWith('label.ip')) {
      return false;
    }
    const [, , context] = menuData.field.split('.');
    return context?.toLowerCase() === 'cve';
  }, [menuData]);

  const isCveFetching = useSelector(cvesSelectors.isFetching);
  const cve = useSelector((state) =>
    isCveMenuMode && menuData?.value
      ? cvesSelectors.getCveById(menuData?.value)(state)
      : null,
  );

  useEffect(() => {
    if (isCveMenuMode && menuData?.value && !cve?.id) {
      dispatch(cvesActions.fetchCveById(menuData.value));
    }
  }, [isCveMenuMode, menuData?.value, cve?.id]);

  const onCveFullDetails = useCallback(
    (id) => () => {
      pageTabMethods.add(`${RoutePaths.cveLookup}?id=${id}`);
    },
    [],
  );

  /** * MENU ** */
  const menuOptions = useMemo(() => {
    /** *
       For "field" data type, we have 2 types of fields:
       1. IPs, Ports have the same button options (grayed out depending):
       - IP Intelligence
       - IP Explorer
       - Follow Stream
       - Search Events
       - Search Flow
       - Search Blocks
       - Copy NQL
       - Whitelist
       - Add Label
       - CVE Lookup (for CVE labels)

       2. Everything else (grayed out depending on):
       - Follow Stream
       - Search Events
       - Search Flow
       - Search Blocks
       - Copy NQL
       - Edit

       For "record" data type we have:
       - Full Details
       - Raw Record
       - Follow Stream
       - Search Flow
       - Push to GF
       ** */

    if (!menuData) {
      return [];
    }

    const isSubAccountRecord =
      menuData?.customer && menuData?.customer !== customer?.shortname;
    const options = [];

    if (menuData.dataType === DataTypes.field && menuData.field) {
      const copyNql = getNqlForLocation({
        flowNql: menuData.flowNql,
        dnsNql: menuData.dnsNql,
        eventNql: menuData.eventNql,
        blockNql: menuData.blockNql,
        pathname: location.pathname,
      });

      options.push(
        ...[
          {
            icon: <MagnifyIcon />,
            text: 'Follow Stream',
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.flow,
                  nql: menuData.streamNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            disabled: !menuData.streamNql,
            tracking: 'follow-stream',
          },
          {
            icon: <MagnifyIcon />,
            text: 'Search Flow',
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.flow,
                  nql: menuData.flowNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            disabled: !menuData?.flowNql,
            tracking: 'search-flow',
          },
          isDnsEnabled && {
            icon: <MagnifyIcon />,
            text: 'Search DNS',
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.dns,
                  nql: menuData.dnsNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            disabled: !menuData?.dnsNql,
            tracking: 'search-dns',
          },
          {
            icon: <MagnifyIcon />,
            text: 'Search Events',
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.alerts,
                  nql: menuData.eventNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            disabled: !menuData.eventNql,
            tracking: 'search-events',
          },
          {
            icon: <MagnifyIcon />,
            text: 'Search Blocks',
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.blocks,
                  nql: menuData.blockNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            disabled: !menuData.blockNql,
            tracking: 'search-blocks',
          },
          {
            icon: <ContentCopyIcon />,
            text: 'Copy NQL',
            copyToClipboard: copyNql,
            disabled: !copyNql,
            tracking: 'copy-nql',
          },
        ].filter(Boolean),
      );

      let isIpPortFieldType = LabelTypes.port === menuData.fieldType;
      if (menuData.fieldType === LabelTypes.ip) {
        isIpPortFieldType = isIP(menuData.value);
      }

      if (isIpPortFieldType) {
        const ipFieldType = getIpFieldType(menuData.field);
        // add to the beginning of the options array
        options.unshift(
          ...[
            {
              icon: <IpIcon />,
              text: 'IP Intelligence',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.ipIntelligence}?ip=${menuData.value}`,
                );
              },
              disabled: menuData.fieldType !== LabelTypes.ip,
              tracking: 'ip-intel',
            },
            {
              icon: <GraphOutlineIcon />,
              text: 'IP Explorer',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.ipExplorer}?ip=${menuData.value}${
                    ipFieldType ? `&type=${ipFieldType}` : ''
                  }`,
                );
              },
              disabled: menuData.fieldType !== LabelTypes.ip,
              tracking: 'ip-explorer',
            },
          ],
        );
        // add to the end of the options array
        options.push(
          ...[
            {
              icon: <PlaylistPlusIcon />,
              text: 'Allow List',
              onClick: onAddToWhitelistToggle,
              disabled:
                isSubAccountRecord ||
                menuData.fieldType !== LabelTypes.ip ||
                !permissionsWhitelist?.update,
              tracking: 'add-to-allowlist',
            },
            {
              icon: <PlaylistPlusIcon />,
              text: 'Discard',
              onClick: onAddToDiscardToggle,
              disabled:
                isSubAccountRecord ||
                menuData.fieldType !== LabelTypes.ip ||
                !permissionsThreatModel?.update,
              tracking: 'add-to-discard',
            },
            {
              icon: <ShapeRectanglePlusIcon />,
              text: 'Add Label',
              onClick: onLabelAdd,
              disabled: isSubAccountRecord || !permissionsLabel?.create,
              tracking: 'add-label',
            },
          ],
        );
      }
    }

    if (menuData.dataType === DataTypes.record && menuData.value) {
      let streamNql;
      if (menuData.recordType !== ContextTypes.audit) {
        streamNql = getStreamNql(record);
      }

      options.push(
        ...[
          {
            icon: <ChartAreasplineIcon />,
            text: 'Full Details',
            onClick: onRecordFullDetails,
            tracking: 'full-details',
          },
          {
            icon: <WindowMaximizeIcon />,
            text: 'Raw Record',
            onClick: onRecordViewRawRecord,
            tracking: 'raw-record',
          },
          {
            onClick: () => {
              pageTabMethods.add(
                getSearchUrl({
                  context: ContextTypes.flow,
                  nql: streamNql,
                  customer: isSubAccountRecord ? menuData.customer : undefined,
                }),
              );
            },
            icon: <MagnifyIcon />,
            text: 'Follow Stream',
            disabled: !streamNql,
            tracking: 'follow-stream',
          },
          (menuData.value.traffic_type === ContextTypes.flow ||
            menuData.recordType === ContextTypes.flow ||
            (!isDnsEnabled && menuData.recordType === ContextTypes.alerts)) && {
            icon: <MagnifyIcon />,
            text: 'Search Flow',
            onClick: () => onRecordSearch(ContextTypes.flow),
            disabled: menuData.recordType === ContextTypes.audit,
            tracking: 'search-flow',
          },
          isDnsEnabled &&
            (menuData.value.traffic_type === ContextTypes.dns ||
              menuData.recordType === ContextTypes.dns) && {
              icon: <MagnifyIcon />,
              text: 'Search DNS',
              onClick: () => onRecordSearch(ContextTypes.dns),
              disabled: menuData.recordType === ContextTypes.audit,
              tracking: 'search-dns',
            },
          {
            icon: <ArrowTopLeftIcon />,
            text: 'Push to GF',
            onClick: onRecordPushToGF,
            disabled: menuData.recordType === ContextTypes.audit,
            tracking: 'push-to-gf',
          },
          {
            icon: <LeadPencilIcon />,
            text: 'Overrides',
            onClick: onRecordOverrides,
            disabled:
              menuData.recordType !== ContextTypes.alerts || isSubAccountRecord,
            tracking: 'overrides',
          },
        ].filter(Boolean),
      );
    }

    if (menuData.additionalMenuOptions) {
      // add additional menu options to the end of the options array
      options.push(...menuData.additionalMenuOptions);
    }

    const shouldEditActionBeVisible =
      options.length &&
      menuData.dataType === DataTypes.field &&
      ![LabelTypes.ip, LabelTypes.port].includes(menuData.fieldType);

    if (shouldEditActionBeVisible) {
      // find element index with text 'Edit' and add it in disabled state if it is not present
      const editActionIndex = options.findIndex(
        (option) => option.text === 'Edit',
      );
      if (editActionIndex === -1) {
        options.push({
          icon: <LeadPencilIcon />,
          text: 'Edit',
          disabled: true,
          tracking: 'edit',
        });
      } else {
        options[editActionIndex] = {
          ...options[editActionIndex],
          disabled: isSubAccountRecord,
        };
      }
    }

    if (isCveMenuMode) {
      options.push({
        icon: <ChartAreasplineIcon />,
        text: 'CVE Lookup',
        onClick: onCveFullDetails(menuData.value),
        tracking: 'cve-lookup',
      });
    }

    return options;
  }, [
    location.pathname,
    permissionsLabel?.create,
    permissionsWhitelist?.update,
    permissionsThreatModel?.update,
    menuData,
    isCveMenuMode,
    onLabelAdd,
    onCveFullDetails,
    onAddToWhitelistToggle,
    onAddToDiscardToggle,
    // Record menu dependencies
    onRecordFullDetails,
    onRecordViewRawRecord,
    onRecordSearch,
    onRecordPushToGF,
    record,
    customer?.shortname,
  ]);

  useEffect(() => {
    setActiveLabelData(null);
  }, [propertiesTray, dataLength]);

  const isActionsMenuHidden =
    !menuOptions.length || (menuData.dataType === DataTypes.record && !record);
  const isLabelMenuMode = !!activeLabelData;

  const contextValue = {
    isActionsMenuHidden,
    menuData,
    menuOptions,
    cve,
    isCveMenuMode,
    isCveFetching,
    isLabelMenuMode,
    setActiveLabelData,
    onLabelClick,
    onLabelEdit,
  };

  const contextValueMemo = useMemo(
    () => contextValue,
    Object.values(contextValue),
  );

  return (
    <Context.Provider value={contextValueMemo}>
      {children}

      {/** * IP LABELS ** */}

      {!!ipLabelToManage && (
        <IpLabelForm
          initialValues={ipLabelToManage}
          onToggle={onIpLabelAddToggle}
          onSubmit={onIpLabelAddSubmit}
          onDelete={onIpLabelDeleteToggle}
          isOpen
        />
      )}

      {!!ipLabelToDelete && (
        <ConfirmModal
          item={`labels for ${ipLabelToDelete?.ip}`}
          onToggle={onIpLabelDeleteToggle}
          onConfirm={onIpLabelDeleteSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {/** * PORT LABELS ** */}

      {!!portLabelToManage && (
        <PortLabelForm
          initialValues={portLabelToManage}
          onToggle={onPortLabelAddToggle}
          onSubmit={onPortLabelAddSubmit}
          onDelete={
            getContextType(portLabelToManage) === LabelContextTypes.customized
              ? onPortLabelResetToggle
              : onPortLabelDeleteToggle
          }
          onVisibilityToggle={onPortLabelVisibilityToggle}
          isOpen
        />
      )}

      {!!portLabelToReset && (
        <ConfirmModal
          item={`labels for ${portLabelToReset.port}`}
          confirmButtonText="Reset"
          whyAsking="This will undo any customization for this system label and restore the system default values."
          onToggle={onPortLabelResetToggle}
          onConfirm={onPortLabelResetSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {!!portLabelToDelete && (
        <ConfirmModal
          item={`labels for ${portLabelToDelete.port}`}
          onToggle={onPortLabelDeleteToggle}
          onConfirm={onPortLabelDeleteSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {/** * WHITELIST ** */}
      {!!allowlist && (
        <AddIpToAllowlistForm
          initialValues={allowlist}
          onToggle={onAddToWhitelistToggle}
          onSubmit={onAddToWhitelistSubmit}
          isOpen
        />
      )}

      {/** * DISCARD ** */}
      {!!discard && (
        <AddIpToDiscardForm
          ip={discard.ip}
          onToggle={onAddToDiscardToggle}
          onSubmit={onAddToDiscardSubmit}
          isOpen
        />
      )}

      {/** * RECORD ** */}
      {recordModalOpened && (
        <RecordModal
          title={getRecordModalTitle(data?.recordType, data?.value)}
          data={record}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Context.Provider>
  );
};

Menu.propTypes = {
  data: PropTypes.object,
  children: PropTypes.children.isRequired,
};

Menu.defaultProps = {
  data: null,
};

export { Context };
export default Menu;
