import { Columns } from './Columns';

export const defaultColumns = [
  Columns.trafficType,
  Columns.timestamp,
  Columns._source,
  Columns._srcPort,
  Columns.datasrc,
  Columns.site,
  Columns.protocol,
  Columns.type,
  Columns.menu,
];
