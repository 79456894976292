import { useCallback } from 'react';

import styled from 'styled-components';

import ContentSaveIcon from 'mdi-react/ContentSaveIcon';

import IconButton from '+components/IconButton';

const FakePresetsButton = styled((props) => {
  const onClick = useCallback(() => {
    const globalFiltersEl = document.getElementById('global-filters');
    const presetsButtonEl =
      globalFiltersEl?.getElementsByClassName('nql-dropdown-btn')[0];
    presetsButtonEl?.click();
  }, []);

  return (
    <IconButton {...props} onClick={onClick} data-tracking="nql-drop-down">
      <ContentSaveIcon />
    </IconButton>
  );
})`
  color: ${({ theme }) => theme.iconButtonInsideComponentsText};
  background-color: ${({ theme }) =>
    theme.iconButtonInsideComponentsBackground};

  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.iconButtonInsideComponentsText};
    background-color: ${({ theme }) =>
      theme.iconButtonInsideComponentsBackground};
  }

  &.MuiIconButton-sizeSmall {
    width: 28px;
    height: 28px;
    padding: 4px;
  }

  &[disabled] {
    opacity: 0.5 !important;
  }
`;

export default FakePresetsButton;
