import { useCallback, useMemo } from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import AddIntegration from '@/pages/Integrations/AddIntegration';
import Allowlists from '@/pages/Integrations/Allowlists';
import AllowlistForm from '@/pages/Integrations/Allowlists/components/AllowlistForm';
import ResponseIntegrationsPage from '@/pages/Integrations/ResponseIntegrations';
import ResponseIntegrationsAdd from '@/pages/Integrations/ResponseIntegrations/Add';
import ResponseIntegrationsEdit from '@/pages/Integrations/ResponseIntegrations/Edit';

import { CrumbRoute } from '+components/Breadcrumb';
import { Tab, TabContent, Tabs, TabsContainer } from '+components/Tabs';

const tabs = {
  integrations: { value: 'integrations', label: 'Integrations' },
  allowlists: { value: 'allowlists', label: 'Allow Lists' },
};

const defaultTab = tabs.integrations;

const rootPath = RoutePaths.integrationsResponse;

const ResponseIntegrationsRoutes = () => {
  const navigate = useNavigate();
  const { params: { id: tabId } = {} } = useMatch(`${rootPath}/:id/*`) || {};

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabId, tabs, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      // if (value === defaultTab.value) {
      //   navigate(rootPath);
      //   return;
      // }
      navigate(`${rootPath}/${value}`);
    },
    [defaultTab],
  );

  const addIntegrationMatch = useMatch(`${rootPath}/add/*`);
  const editIntegrationMatch = useMatch(`${rootPath}/edit/*`);
  const addAllowlistMatch = useMatch(`${rootPath}/allowlists/add/*`);
  const editAllowlistMatch = useMatch(`${rootPath}/allowlists/:id/*`);
  const hideTabs =
    addIntegrationMatch ||
    editIntegrationMatch ||
    addAllowlistMatch ||
    editAllowlistMatch;

  return (
    <TabsContainer>
      {!hideTabs && (
        <Tabs value={currentTab.value} onChange={onTabChange}>
          {Object.values(tabs).map(({ value, label }) => (
            <Tab key={value} label={label} value={value} />
          ))}
        </Tabs>
      )}

      <TabContent>
        <Routes>
          <Route
            path="edit/:id/*"
            element={
              <CrumbRoute
                title="Edit Response Integration"
                component={ResponseIntegrationsEdit}
              />
            }
          />

          <Route
            path="add/:adapter"
            element={
              <CrumbRoute
                title="Add Response Integration"
                component={ResponseIntegrationsAdd}
              />
            }
          />

          <Route
            path="add"
            element={
              <CrumbRoute title="Add Integration" component={AddIntegration} />
            }
          />

          <Route path="allowlists/*">
            <Route path=":id/*" element={<AllowlistForm />} />
            <Route
              path="*"
              element={
                <CrumbRoute title="Allow Lists" component={Allowlists} />
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <CrumbRoute
                title="Response Integrations"
                component={ResponseIntegrationsPage}
              />
            }
          />
        </Routes>
      </TabContent>
    </TabsContainer>
  );
};

export default ResponseIntegrationsRoutes;
