import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { makeId } from '+utils';
import nqlLang from '+utils/nqlLang';

import heatmapFormatting from './HeatmapFormatting';

const seriesId = makeId();

export const useAlertsHeatmap = (filters, userFilters, interval) => {
  const timeSeriesRequest = useMemo(
    () => ({
      seriesId,
      params: {
        start: userFilters.start,
        end: userFilters.end,
        series: [
          {
            metric: 'alerts',
            name: 'events-heatmap',
            interval,
            ...StatsRequest.makeSearch({
              search: filters.nql,
              intersect: filters.intersect,
              andSearch: userFilters.algorithm
                ? nqlLang.equal('algorithm', userFilters.algorithm)
                : '',
            }),
          },
        ],
        customers: filters.customers,
      },
    }),
    [
      userFilters.start,
      userFilters.end,
      userFilters.algorithm,
      filters.nql,
      filters.intersect,
      filters.customers,
      interval,
    ],
  );

  const { series, isFetching, pollingHeartbeat } = useStatsRequest({
    context: ContextTypes.alerts,
    requestType: StatsRequest.Types.ts,
    request: timeSeriesRequest,
    clearIfRequestChanged: false,
  });

  useLoadingIndicator(isFetching);

  const heatmap = useMemo(
    () =>
      heatmapFormatting(series?.[0]?.data, series?.[0]?.interval ?? interval),
    [series?.[0]?.data, series?.[0]?.interval, interval],
  );

  return [isFetching, heatmap, pollingHeartbeat];
};

export default useAlertsHeatmap;
