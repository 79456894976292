import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { makeId } from '+utils';

const seriesId = makeId();

// a sort function by score and count
const sortByScoreAndCount = (a, b) =>
  b.total_score - a.total_score || b.count - a.count;

const sortByTotalScore = ([, a], [, b]) => b - a;

const sum = (arr) => arr.reduce((acc, e) => acc + e, 0);

export const useIpRateData = (filters, userFilters, algorithms) => {
  const request = useMemo(
    () => ({
      seriesId,
      params: {
        start: userFilters.start,
        end: userFilters.end,
        format: 'keymap',
        series: [
          {
            metric: 'total_score',
            field: [
              'ip',
              'ndm.name',
              'ndm_score_threat',
              'ndm_score_confidence',
              'total_score',
              !!filters.customers?.length && 'customer',
            ].filter(Boolean),
            name: 'asset-summary-table',
            size: 1000,
          },
        ],
        customers: filters.customers,
      },
    }),
    [userFilters.start, userFilters.end, filters.customers],
  );

  const { series, isFetching } = useStatsRequest({
    context: ContextTypes.ip,
    requestType: StatsRequest.Types.agg,
    request,
    clearIfRequestChanged: false,
  });

  useLoadingIndicator(isFetching);

  const ips = useMemo(() => {
    const result = (series?.[0]?.buckets || []).reduce((acc, e) => {
      const key = e.ip;
      let item = acc[key];
      if (!item) {
        item = {
          ip: key,
          algorithms: new Set(),
          events: [],
          scoreThreat: [],
          scoreConfidence: [],
          totalScore: [],
          customer: new Set(),
        };
        acc[key] = item;
      }

      if (e['ndm.name'] != null) {
        item.algorithms.add(e['ndm.name']);
      }
      item.customer.add(e.customer);

      const scoreThreat = e.ndm_score_threat;
      const scoreConfidence = e.ndm_score_confidence;
      const totalScore = e.total_score;

      const events = Math.ceil(+(totalScore && e.count / totalScore));

      item.events.push(events);
      item.totalScore.push(e.count);
      item.scoreThreat.push(scoreThreat * events);
      item.scoreConfidence.push(scoreConfidence * events);

      return acc;
    }, {});

    Object.values(result).forEach((value) => {
      value.events = sum(value.events);
      value.totalScore = Math.ceil(sum(value.totalScore));
      value.avgNdmThreadScore = +(
        sum(value.scoreThreat) / value.events
      ).toFixed(3);
      value.avgNdmConfidenceScore = +(
        sum(value.scoreConfidence) / value.events
      ).toFixed(3);

      delete value.scoreThreat;
      delete value.scoreConfidence;
    });

    return result;
  }, [series]);

  const algorithmsValues = useMemo(
    () => Object.values(algorithms || {}),
    [algorithms],
  );

  const tableData = useMemo(
    () =>
      Object.values(ips).map((item) => {
        const dms = Array.from(item.algorithms)
          .filter(Boolean)
          .map(
            (name) =>
              algorithmsValues?.find((e) => e.name === name) || { name },
          );
        const categories = Array.from(
          new Set(dms.flatMap((dm) => dm.categories).filter(Boolean)),
        ).sort();

        return {
          ...item,
          algorithms: dms.map((dm) => dm.name).sort(),
          categories,
          customer: Array.from(item.customer).filter(Boolean),
        };
      }),
    [ips, algorithmsValues],
  );

  const chartData = useMemo(() => {
    const result = Object.values(
      Object.values(ips)
        .sort(sortByScoreAndCount)
        .reduce((acc, ip) => {
          const x = ip.avgNdmThreadScore;
          const y = ip.avgNdmConfidenceScore;
          const key = `${x}-${y}`;
          let item = acc[key];
          if (!item) {
            item = {
              algorithms: ip.algorithms,
              avgNdmThreadScore: x,
              avgNdmConfidenceScore: y,
              categories: new Set(),
              events: ip.events,
              totalScore: ip.totalScore,
              value: ip.ip,
              x,
              y,
              z: ip.totalScore,
              ips: { [ip.ip]: ip.totalScore },
            };
            acc[key] = item;
          }

          const dms = Array.from(item.algorithms)
            .filter(Boolean)
            .map(
              (name) =>
                algorithmsValues?.find((e) => e.name === name) || { name },
            );

          const categories = new Set(
            dms.flatMap((dm) => dm.categories).filter(Boolean),
          );

          item.ips[ip.ip] = ip.totalScore;
          item.categories = categories;
          item.events = ip.events;
          item.totalScore = Math.max(item.totalScore, ip.totalScore);
          item.z = item.totalScore;

          return acc;
        }, {}),
    );

    result.forEach((item) => {
      item.ips = Object.entries(item.ips)
        .sort(sortByTotalScore)
        .map(([ip, totalScore]) => ({
          ip,
          totalScore,
        }));
    });

    return result;
  }, [ips, algorithmsValues]);

  const scoreMinMax = useMemo(() => {
    const result = [Infinity, -Infinity];
    tableData?.forEach((item) => {
      const { totalScore: score } = item;
      result[0] = Math.min(result[0], score);
      result[1] = Math.max(result[1], score);
    });

    return result;
  }, [tableData]);

  return [tableData, chartData, scoreMinMax];
};
