import { Route, Routes } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import CveLookup from '@/pages/CveLookup';
import IpIntelligence from '@/pages/IpIntelligence';

import { CrumbRoute } from '+components/Breadcrumb';

const ToolsRoutes = () => (
  <Routes>
    <Route
      path={RoutePaths.ipIntelligence.pageName}
      element={
        <CrumbRoute title="IP Intelligence" component={IpIntelligence} />
      }
    />

    <Route
      path={RoutePaths.cveLookup.pageName}
      element={<CrumbRoute title="CVE Lookup" component={CveLookup} />}
    />

    <Route path="*" element={<IpIntelligence />} />
  </Routes>
);

export default ToolsRoutes;
